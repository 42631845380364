export const IMAGES_URL = {
    LOGO: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Flogo.png?alt=media&token=831f6b85-5211-4f90-a9bd-eb46e5d2e890",
    HEADER: {
        DRAWER_BACKGROUND: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fsidemenu%20copy.jpg?alt=media&token=4235963e-fd5f-42f6-97bf-e268736ee055"
    },
    ABOUT_ME: {
        DOCUMENTATY: "https://www.youtube.com/embed/XiQyndYZnOE"
    },
    CONTACT: {
        BACKGROUND: "https://disrv.com/wp-content/uploads/2016/02/Consulting-Over-Computers-Image-Dark-Background.jpg"
    },
    NEWS_LETTER: {
        BACKGROUND_MOBILE:"https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fnewsletter-mobile.jpg?alt=media&token=7d423ce0-e834-4a69-9a31-0c2bf55b73ac",
        BACKGROUND_DESKTOP:"https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fnewsletter%20copy.jpg?alt=media&token=505576de-9d38-48de-8c3b-d1b0f641774d"
    },
    SERVICES: {
        PERSONAL_BUTTON:"https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fpersonal%2Fpersonal_button.png?alt=media&token=cae2d9a1-971a-4771-8ae6-d1f4989ba8fa",
        NUTRITION_BUTTON:"https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fnutrition%2Fnutriton_button.png?alt=media&token=47af9bf0-f78e-415d-8477-45a3fccbcefb",
        ONLINE_BUTTON: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fonline%2Fonline_button.png?alt=media&token=1be2e41c-7bd9-4cf6-b828-931d5e558202",

        PERSONAL:"https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fpersonal%2Fpersonal.png?alt=media&token=fa3604f6-0bea-48b9-8e24-48bd17fa68f5",
        NUTRITION:"https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fnutrition%2Fnutrition.png?alt=media&token=dd2f74a9-2b34-4a5a-923a-05fa3633962a",
        ONLINE: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fonline%2Fonline.png?alt=media&token=7ce2550e-5937-49f3-a91f-70ca5a2a5ce1",

        NUTRITION_SAMPLE: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fnutrition%2Fnutrition_sample.gif?alt=media&token=027d325c-580b-430a-830c-7ea969313f19",
        ONLINE_SAMPLE_1: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fonline%2Flibrary_sample.gif?alt=media&token=af0712c5-5e74-4c83-aedb-a917badea18d",
        ONLINE_SAMPLE_2: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fonline%2Fexercise_sample.gif?alt=media&token=94720439-08ca-4bfd-81fb-048843af4fa8",
        ONLINE_MOBILE_APP: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fonline%2Fholding_phone_calendar.png?alt=media&token=e265a37d-0908-4661-b169-c2ad17fdff60",
        PERSONAL_MIAMI: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Fservices%2Fpersonal%2Fmiami.jpg?alt=media&token=2e8ec526-3288-488b-90dd-4ef659300c27"
    },
    LANGUAGE_BUTTON: {
        ENGLISH: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Flanguages%2Fenglish.jpg?alt=media&token=a6b06dee-ebd9-4415-b145-fb244a721c01",
        SPANISH: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2Flanguages%2Fspanish.png?alt=media&token=3fd31b1b-faff-44d0-a0af-9cdf3f90cdf4"
    }
}