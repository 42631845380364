import React from "react";
import { useParams } from "react-router-dom";
import { NAVIGATION_LINKS } from "../../_const";


export default function Wrapper(props) {
    const { children } = props;
    const navigationLinksKeys = Object.keys(NAVIGATION_LINKS);
    let { section = NAVIGATION_LINKS[navigationLinksKeys[0]] } = useParams();
    React.useEffect(() => {
        const element = document.getElementById(section);
        if (element) {
            const y = element.getBoundingClientRect().top + window.pageYOffset + -100;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }, [section]);
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
}
