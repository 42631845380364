import { Avatar, makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import { Zoom, Rotate, Fade } from "react-reveal";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 50,
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    avatar: {
        top: -50,
        position: "absolute",
        color: theme.palette.primary.light,
        zIndex: 2,
    },
    line: {
        zIndex: 1,
        position: "absolute",
        width: "100%",
        height: 10,
        borderRadius: 1,
        [theme.breakpoints.down("xs")]: {
            height: 5
        }
    }
}))
export default function IconDivider(props) {
    const theme = useTheme();
    const { children, avatarSize = 50, color = theme.palette.primary.light } = props;
    const classes = useStyles();
    return (
        <div className={classes.root} style={{ height: avatarSize }}>
            <div className={classes.avatar}>
                <Zoom>
                    <Avatar style={{
                        width: avatarSize,
                        height: avatarSize,
                        backgroundColor: theme.palette[color].light,
                        color: theme.palette.getContrastText(theme.palette[color].light)
                    }}>
                        <Rotate>
                            {children}
                        </Rotate>
                    </Avatar>
                </Zoom>
            </div>
            <Fade>
                <div className={classes.line}
                    style={{
                        backgroundColor: theme.palette[color].light
                    }}></div>
            </Fade>
        </div>
    )
}