import {
  IconButton,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { IMAGES_URL, NAVIGATION_LINKS } from "../../_const";
import { useHistory } from "react-router-dom";
import featureVideo from "../../assets/videos/websitemovie.mp4";
import featureVideMobile from "../../assets/videos/websitemoviemobileversion.mp4";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: theme.palette.primary.main,
  },
  wrapper: {
    boxSizing: "border-box!important",
  },
  overlay: {
    height: "100%",
    width: "100%",
    backgroundColor: "var(--background-color)",
    position: "absolute",
    zIndex: 1,
    display: "none",
  },
  media: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    overflow: "hidden",
    pointerEvents: "none",
  },
  video: {
    boxSizing: "border-box!important",
    height: "100%",
  },
  frame: {
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    width: "300%",
    left: "-100%",
    maxWidth: "none",
    height: "100%",
  },
  buttonWrapper: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    textAlign: "center",
  },
  logo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  },
  hide: {
    visibility: "hidden",
  },
}));
export default function Home(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [videoLoaded, setVideoLoaded] = React.useState(false);
  const src = isMobile ? featureVideMobile : featureVideo;
  const handleNavigation = () => {
    const nextSection = NAVIGATION_LINKS[Object.keys(NAVIGATION_LINKS)[1]];
    if (history.location.pathname === `/${nextSection}`) {
      const element = document.getElementById(nextSection);
      const y = element.getBoundingClientRect().top + window.pageYOffset + -100;
      window.scrollTo({ top: y, behavior: "smooth" });
    } else {
      history.push(nextSection);
    }
  };
  return (
    <div id={NAVIGATION_LINKS.HOME} className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.overlay}></div>
        <div className={classes.media}>
          <div className={classes.video}>
            <video
              autoPlay
              loop
              muted
              playsInline
              style={
                isMobile
                  ? { objectFit: "cover" }
                  : { minWidth: "100%", minHeight: "100%" }
              }
              disablePictureInPicture
              onLoadedData={() => setVideoLoaded(true)}
              className={!videoLoaded ? classes.hide : undefined}
            >
              <source src={src} type="video/mp4" />
            </video>
            <img
              className={`${classes.logo} ${videoLoaded && classes.hide}`}
              src={IMAGES_URL.LOGO}
              alt="Every Step Hurt view logo"
            />
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <IconButton
            color="secondary"
            className={classes.button}
            onClick={() => handleNavigation()}
          >
            <KeyboardArrowDownIcon
              style={{ fontSize: 100 }}
            ></KeyboardArrowDownIcon>
          </IconButton>
        </div>
      </div>
    </div>
  );
}
