export const SPANISH_TEXT = {
  HEADER: {
    SERVICES: "Servicios",
    ABOUT_ME: "Acerca de mí",
    TRANSFORMATIONS: "Transformaciones",
    START_TRAINING: "Entrenamiento",
  },
  SERVICES: {
    ONLINE_LABEL: "Entrenamiento Online",
    NUTRITION_LABEL: "Nutrición",
    PERSONAL_TRAINING_LABEL: "Entrenamiento en Personal",
    ONLINE_0: (
      <span>
        Una <b>aplicación</b> fácil de usar
      </span>
    ),
    ONLINE_1: (
      <span>
        <b>24/7 </b> apoyo
      </span>
    ),
    ONLINE_2: <span>Traqueo de nutrición</span>,
    ONLINE_3: <span>Videos y fotos de los ejercicios</span>,
    ONLINE_DESCRIPTION: (
      <span>
        Te proveemos con una{" "}
        <strong>aplicación de teléfono fácil de usar</strong> que te guiará a
        través de las 4 semanas. Te mostrará la forma correcta de hacer cada
        ejercicio. Apoyo 24/7 a través de mensajes de texto. Te personalizamos
        un plan de nutrición que te ayudará a alcanzar tus{" "}
        <strong>metas de ejercicios</strong>
      </span>
    ),
    NUTRITION_DESCRIPTION: (
      <span>
        Te ayudamos a entender, aprender trucos, así como diferentes formas para{" "}
        <strong>controlar tu nutrición</strong>. Toda la información en un PDF,{" "}
        <strong>fácil de leer y entender</strong>, con un plan de dieta
      </span>
    ),
    NUTRITION_CARD_HEADER: "Muestra Nutriton Plan",
    NUTRITION_0: (
      <span>
        Un <strong>pdf o plan nutritivo</strong> que ofrece las bases para una
        alimentación saludable cada día
      </span>
    ),
    NUTRITION_1: (
      <span>
        Te ayudamos a entender cómo funciona la <strong>nutrición</strong>
      </span>
    ),
    NUTRITION_2: (
      <span>Una lista de comidas y ejemplos de planes de dieta</span>
    ),
    NUTRITION_CONTACT_TITLE: "¿Necesitas más información?",
    NUTRITION_CONTACT_BODY:
      "Escriba su correo electrónico, para que podamos enviarle toda la información que necesita para comenzar su viaje.",
    NUTRITION_CONTACT_HELPER_TEXT:
      "Por favor escriba un correo electrónico válido",
    NUTRITION_CONTACT_BUTTON: "Envíame Información",
    NUTRITION_CONTACT_SUCCESS_TITLE:
      "Gracias por proporcionar su correo electrónico",
    NUTRITION_CONTACT_SUCCESS_BODY: "¡Nos comunicaremos contigo muy pronto!",
    PERSON_0: (
      <span>
        Entrenamiento <b>1 y 1</b>
      </span>
    ),
    PERSON_1: (
      <span>
        Más de <b>10</b> años de experiencia
      </span>
    ),
    PERSON_2: <span>Localización: Tu gimnasio favorito</span>,
    PERSON_3: <span>Guia nutritiva</span>,
    PERSON_4: <span>Traqueo de progreso</span>,
    PERSON_5: <span>Técnicas y secretos</span>,
    PERSON_DESCRIPTION: (
      <span>
        Entrenamiento de alta calidad. Nuestros{" "}
        <strong>10 años de experiencia</strong> aplicados en ayudarte a alcanzar
        tu meta: Una vida más activa y saludable
      </span>
    ),
    PERSON_CONTACT: "¿Vives en el área de Miami?",
    PERSON_CONTACT_BUTTON_1: "Si",
    PERSON_CONTACT_TITLE: (
      <span>
        <b>¿Quieres más información sobre el entrenamiento en persona?</b>
      </span>
    ),
    PERSON_CONTACT_BODY: (
      <span>
        Escriba su <b> número o correo electrónico </b> y nos comunicaremos
        contigo pronto.
      </span>
    ),
    PERSON_CONTACT_OR: "O",
    PERSON_CONTACT_PHONE_NUMBER: "Numero de Telefono",
    PERSON_CONTACT_BUTTON_2: "Contáctame",
    PERSON_CONTACT_SUCCESS_TITLE: "Gracias por compartir tu información",
    PERSON_CONTACT_SUCCESS_BODY: "¡Nos comunicaremos contigo muy pronto!",
  },
  NEWSLETTER: {
    SIGN_UP: "Inscribirse",
    SIGN_UP_DESCRIPTION: (
      <span>
        Inscríbete para una <b>oportunidad</b> de ganarte un Programa de
        Ejercicios <b>Gratis</b>
      </span>
    ),
    HELPER_TEXT: "Proporcione un correo electrónico válido",
    SUCCESS_MESSAGE_TITLE: (
      <span>Gracias por proporcionar su correo electrónico</span>
    ),
    SUCCESS_MESSAGE_BODY: (
      <span>
        Tu plan de entrenamiento <b> GRATIS </b> está en camino
      </span>
    ),
  },
  ABOUT_ME: {
    ABOUT_ME_TITLE_LABEL: "Every Step Hurt Documental",
  },
  TESTIMONIES: {
    TESTIMONIES_TITLE_LABEL: "Transformaciones",
  },
  START_QUESTIONS: {
    TITLE: "¿Estás listo para cambiar tu vida?",
    BODY: "Completa el siguiente cuestionario para empezar el cambio ahora!",
    BUTTON: "Empezar",
  },
  QUESTIONS: {
    NAME: "Nombre",
    AGE: "Edad",
    SEX: "Sexo",
    HEIGHT: "Altura",
    WEIGHT: "Peso",
    ADDRESS: "Calle",
    CITY: "Ciudad",
    STATE: "Estado",
    ZIP_CODE: "Codigo Postal",
    PHONE: "Telefono",
    EMAIL: "Email",
    HEARTH_CONDITION:
      "¿Su médico le ha dicho alguna vez que tiene una afección cardíaca y que solo debe realizar la actividad física recomendada por un médico?",
    HEARD_ABOUT_ME: "¿Cómo escucho acerca de mí?",
    HEARD_ABOUT_ME_OPTIONS: [
      { label: "Instagram", value: "instagram" },
      { label: "Facebook", value: "facebook" },
      { label: "Youtube", value: "youtube" },
      { label: "Amigo/Familia", value: "family" },
      { label: "Otro", value: "other" },
    ],
    YES_NO_ERROR_MESSAGE: "Por favor seleccione SI o NO",
    RESPONSE_MESSAGE: `Si ha respondido “Sí” a una o más de las preguntas anteriores, consulte a su médico antes de realizar actividad física. 
        Dígale a su médico a qué preguntas respondió "Sí". 
        Después de una evaluación médica, busque el consejo de su médico sobre qué tipo de actividad es adecuada para su condición actual.`,
    CHEST_PAIN: "¿Siente dolor en el pecho cuando realiza actividad física?",
    PAST_CHEST_PAIN: `En el último mes, ¿ha tenido dolor en el pecho cuando no realizaba ninguna actividad física?`,
    LOSE_BALANCE: `¿Pierde el equilibrio debido a los mareos o alguna vez pierde el conocimiento?`,
    BONE_PROBLEM: `¿Tiene algún problema en los huesos o en las articulaciones que podría empeorar con un cambio en su actividad física?`,
    PRESCRIBING_MEDICATION: `¿Su médico le receta actualmente algún medicamento para la presión arterial o para una afección cardíaca?`,
    OTHER_REASON: `¿Conoce alguna otra razón por la que no debería realizar actividad física?`,
    CURRENT_POSITION: `¿Cuál es su ocupación actual?`,
    CURRENT_POSITION_ERROR: `Please enter your current job position`,
    OCCUPATION_MOVEMENT: `¿Su ocupación requiere períodos prolongados de estar sentado?`,
    REPETITIVE_MOVEMENT: `¿Su ocupación requiere períodos prolongados de movimientos repetitivos?`,
    HEEL_SHOES:
      "¿Su ocupación requiere que use zapatos con tacón (zapatos de vestir)?",
    ANXIETY: "¿Su ocupación le causa ansiedad (estrés mental)?",
    RECREATIONAL_ACTIVITIES: `¿Participa en alguna actividad recreativa (golf, tenis, esquí, etc.)?`,
    HOBBIES: `¿Tiene algún pasatiempo (lectura, jardinería, trabajar en automóviles, explorar Internet, etc.)?`,
    PLEASE_EXPLAIN: "Por favor explique",
    PREVIOUS_INJURIES: `¿Ha tenido alguna vez algún dolor o lesión (tobillo, rodilla, cadera, espalda, hombro, etc.)?`,
    SURGERIES: `¿Ha tenido alguna cirugía?`,
    CHRONIC_DISEASE: `¿Alguna vez un médico le ha diagnosticado una enfermedad crónica, como enfermedad coronaria, enfermedad de las arterias coronarias, hipertensión (presión arterial alta), colesterol alto o diabetes?`,
    MEDICATION: `¿Actualmente estás tomando algún medicamento?`,
    IDEAL_GOAL: `¿Cuál es tu objetivo de fitness? (bajar de peso, hacerse más fuerte, prepararse para un maratón?)`,
    GYM: `¿Dónde y en qué tipo de gimnasio puedes ejercitarte?`,
    WORKOUT_DAYS: `¿Cuántos días puedes hacer ejercicio en una semana?`,
    HEALTHY_FOOD_INTAKE: `¿Qué alimentos saludables comes con regularidad?`,
    LAST_TIME_WORKOUT: `¿Cuándo fue la última vez que hizo ejercicio?`,
    SEX_OPTIONS: [
      { label: "Hombre", value: "male" },
      { label: "Mujer", value: "female" },
    ],
    SEND_BUTTON: "Enviar",
    RESET_BUTTON: "Reiniciar",
    QUESTION_GENERAL: "Información contacto",
    QUESTION_PHYSICAL_ACTIVITY:
      "CUESTIONARIO DE PREPARACIÓN PARA LA ACTIVIDAD FÍSICA (PAR-Q)",
    QUESTION_MEDICAL: "CUESTIONARIO GENERAL Y MÉDICO",
    QUESTION_QUESTIONS: "Preguntas",
    QUESTION_OCCUPATIONAL: "Preguntas ocupacionales",
    QUESTION_RECREATIONAL: "Preguntas recreativas",
    QUESTION_SUB_GROUP_MEDICAL: "Preguntas medicas",
    QUESTION_SMARTGOALS: "Metas INTELIGENTES",
    YES_OPTION: "Si",
    NO_OPTION: "No",
    QUESTION_SUBMIT_SUCCESS_TITTLE: "Gracias por completar este cuestionario",
    QUESTION_SUBMIT_SUCCESS_BODY: "¡Nos comunicaremos contigo pronto!",
    QUESTION_SUBMIT_ERROR_TITLE: "Sufrimos algunas dificultades técnicas",
    QUESTION_SUBMIT_ERROR_BODY: "¡Por favor, inténtelo de nuevo más tarde!",
  },
};
