import { Button, Container, Grid, Typography } from "@material-ui/core";
import React from "react";
import {connect} from "react-redux";
import { PAGE_TEXT_KEYS } from "../../../_const";
import { getLabelText, getPageText } from "../../../_helpers";

const pageTextKey = "START_QUESTIONS";
function StartQuestions(props) {

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" color="secondary" align="center">
                        {getLabelText(props,PAGE_TEXT_KEYS.TITLE)}
                    </Typography>
                    <Typography variant="subtitle1" color="secondary" align="center">
                        {getLabelText(props,PAGE_TEXT_KEYS.BODY)}
                    </Typography>
                </Grid>
                <Grid item xs={12} container justify="center">
                    <Button variant="contained" color="primary" onClick={() => props.handleStartClick()}>
                        {getLabelText(props,PAGE_TEXT_KEYS.BUTTON)}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

function mapStateToProps(state) {
    return getPageText(state,pageTextKey);
}

const connectedStartQuestions = connect(mapStateToProps)(StartQuestions);
export  { connectedStartQuestions as StartQuestions };
