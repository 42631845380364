import React from "react";
import { makeStyles, Typography, ButtonBase } from "@material-ui/core";
import { IMAGES_URL, NAVIGATION_LINKS } from "../../_const";
import {OnlineBody} from "./Online/Online";
import {NutritionBody} from "./Nutrition/Nutrition";
import {PersonBody} from "./Person/PersonBody";
import {connect} from "react-redux";
import { getPageText,getLabelText } from "../../_helpers";
const pageTextKey = "SERVICES";

const services = [
    {
        id: "online",
        label: "ONLINE_LABEL",
        image: IMAGES_URL.SERVICES.ONLINE_BUTTON,
        body: (
            <OnlineBody/>
        )
    },
    {
        id: "nutrition",
        label: "NUTRITION_LABEL",
        image: IMAGES_URL.SERVICES.NUTRITION_BUTTON,
        body: (
            <NutritionBody/>
        )
    },
    {
        id: "personalTrainer",
        label: "PERSONAL_TRAINING_LABEL",
        image: IMAGES_URL.SERVICES.PERSONAL_BUTTON,
        body: (
           <PersonBody/>
        )
    }
]
const useStyles = makeStyles((theme) => ({
    root: {
        //backgroundColor: theme.palette.secondary.main,
        paddingBottom: theme.spacing(5),
    },
    paper: {
        padding: theme.spacing(1)
    },
    featureImage: {
        width: "90%",
        height: "100%"
    },
    cardRoot: {
        textAlign: "left"
    },
    serviceImage: {
        width: "100%",
        borderRadius: 50,
        borderColor: theme.palette.primary.light,
        borderWidth: "medium",
        borderStyle: "groove",
    },
    buttonsRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
        //marginBottom: theme.spacing(5)
    },
    image: {
        position: 'relative',
        height: "90vh",
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: `${100 / services.length}vh`,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    divider: {
        borderTop: `3px solid ${theme.palette.primary.light}`,
        borderRadius: 5,
        width: "90%"
    }
}));



function Services(props) {
    const classes = useStyles();
    const handleNavigation = (event) => {
        const id = event.currentTarget.id;
        console.log(id)
        const element = document.getElementById(id + "Section");
        const y = element.getBoundingClientRect().top + window.pageYOffset + -100;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }
    return (

        <section id={NAVIGATION_LINKS.SERVICES} className={classes.root}>
            <div className={classes.buttonsRoot}>
                {services.map((service) => (
                    <ButtonBase
                        id={service.id}
                        focusRipple
                        key={service.label}
                        className={classes.image}
                        focusVisibleClassName={classes.focusVisible}
                        style={{
                            width: `${100 / services.length}%`,
                        }}
                        onClick={handleNavigation}
                    >
                        <span
                            className={classes.imageSrc}
                            style={{
                                backgroundImage: `url(${service.image})`,
                            }}
                        />
                        <span className={classes.imageBackdrop} />
                        <span className={classes.imageButton}>
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                className={classes.imageTitle}
                            >
                                {getLabelText(props,service.label)}
                                <span className={classes.imageMarked} />
                            </Typography>
                        </span>
                    </ButtonBase>
                ))}
            </div>
            {services.map((service, index) => {
                return (
                    <section key={service.id} id={service.id + "Section"}>
                        {service.body}
                    </section>
                );
            })}
        </section>
    )
}

function mapStateToProps(state) {
    const { ONLINE_LABEL, NUTRITION_LABEL,PERSONAL_TRAINING_LABEL  } = getPageText(state,pageTextKey);
    return { ONLINE_LABEL, NUTRITION_LABEL,PERSONAL_TRAINING_LABEL }
}

const connectedServices = connect(mapStateToProps)(Services);
export  { connectedServices as Services };