import { Button, CircularProgress, Container, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";
import { NAVIGATION_LINKS } from "../../_const/navigation";
import { getLabelText, getPageText, httpServices } from "../../_helpers";
import SendIcon from '@material-ui/icons/Send';
import { IMAGES_URL } from "../../_const/assets_urls";
import {connect} from "react-redux";
import { PAGE_TEXT_KEYS } from "../../_const";

const pageTextKey = PAGE_TEXT_KEYS.NEWSLETTER;

const useStyles = makeStyles((theme) => ({
    root: {
        height: 500,
        background: `url(${IMAGES_URL.NEWS_LETTER.BACKGROUND_DESKTOP})`,
        backgroundSize: "cover",
        [theme.breakpoints.down('xs')]: {
            background: `url(${IMAGES_URL.NEWS_LETTER.BACKGROUND_MOBILE})`,
            backgroundSize: "cover"
        }
    },
    full: {
        height: "100%"
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
                borderColor: theme.palette.secondary.main,
            }
        },
        '& label': {
            color: theme.palette.secondary.main
        },
        '&:hover fieldset': {
            borderColor: theme.palette.secondary.main,
        },
    }
}))
function NewsLetter(props) {
    const classes = useStyles();
    const [sendingEmail, setSendingEmail] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [emailSent, setEmailSent] = React.useState(false);
    const [notValidEmail, setNotValidEmail] = React.useState(false);
    const handleChange = (event) => {
        const { value } = event.target;
        setEmail(value);
    }
    const sendEmail = () => {
        if (validateEmail()) {
            setSendingEmail(true);
            const htmlBody = `<h1>Sing Up to News Letter</h1>
            <p><b>Email</b> ${email}</p>`;
            httpServices.post("https://us-central1-everystephurt.cloudfunctions.net/sendMail", { emailSubject: "News Letter Sign Up", emailBody: htmlBody })
                .then(
                    response => {
                        setSendingEmail(false);
                        setEmailSent(true);
                    },
                    error => {
                        console.log(error);
                        setSendingEmail(false);
                    }
                );
        }
    }
    const validateEmail = () => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            setNotValidEmail(false)
            return (true)
        }
        setNotValidEmail(true)
        return (false)
    }

   
    
    return (
        <section id={NAVIGATION_LINKS.NEWSLETTER} className={classes.root}>
            <Container className={classes.full}>
                {!emailSent ? (
                    <Grid container justify="flex-end" alignItems="center" className={classes.full}>
                        <Grid item xs={12} sm={6} container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h3" align="center" color="secondary">{getLabelText(props,PAGE_TEXT_KEYS.SIGN_UP_DESCRIPTION)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField className={classes.textField}
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    label="Email"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}
                                    type="email"
                                    error={notValidEmail}
                                    helperText={notValidEmail && getLabelText(props,PAGE_TEXT_KEYS.HELPER_TEXT)}
                                ></TextField>
                            </Grid>
                            <Grid item xs={12} container justify="flex-end">
                                <Button
                                    disabled={sendingEmail}
                                    onClick={sendEmail}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<SendIcon />}>
                                    {sendingEmail ? <CircularProgress size={24} color="secondary" /> : getLabelText(props,PAGE_TEXT_KEYS.SIGN_UP)}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                        <Grid container justify="flex-end" alignItems="center" className={classes.full}>
                            <Grid item xs={12} sm={6} container direction="column" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" align="center" color="secondary">{getLabelText(props,PAGE_TEXT_KEYS.SUCCESS_MESSAGE_TITLE)}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="center" color="secondary">{getLabelText(props,PAGE_TEXT_KEYS.SUCCESS_MESSAGE_BODY)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

            </Container>
        </section>
    )
}

function mapStateToProps(state) {
    return getPageText(state,pageTextKey);
}

const connectedNewsLetter = connect(mapStateToProps)(NewsLetter);
export  { connectedNewsLetter as NewsLetter };