import {
  Fade,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import { QUESTION_DECISION_OPTIONS, QUESTION_TYPE } from "../../_const";
import { getLabelText } from "../../_helpers";

export default function Question(props) {
  const { disable, question, pageText } = props;
  const {
    id,
    type,
    value,
    showJustificationWith,
    justificationValue,
    required,
    errorMessage,
  } = question;
  const handleChange = (event) => {
    const { name, value } = event;
    props.question[name] = value;
    props.onChange(props.question);
  };
  let label = getLabelText(pageText, question.label);
  let justificationPlaceholder = getLabelText(
    pageText,
    question.justificationPlaceholder
  );
  let options = getLabelText(pageText, question.options);
  const getBody = () => {
    switch (type) {
      case QUESTION_TYPE.INPUT:
        return (
          <InputQuestion
            id={id}
            label={label}
            value={value}
            handleChange={handleChange}
            disable={disable}
            required={required}
            errorMessage={errorMessage}
          />
        );
      case QUESTION_TYPE.YESORNO:
        return (
          <DecisionQuestion
            id={id}
            label={label}
            value={value}
            handleChange={handleChange}
            disable={disable}
            pageText={pageText}
            required={required}
          />
        );
      case QUESTION_TYPE.YESORNOWITHJUSTIFICATION:
        return (
          <DecisionQuestion
            id={id}
            label={label}
            value={value}
            handleChange={handleChange}
            disable={disable}
            required={required}
            showJustificationWith={showJustificationWith}
            justificationValue={justificationValue}
            justificationPlaceholder={justificationPlaceholder}
            pageText={pageText}
          />
        );
      case QUESTION_TYPE.DROPDOWN:
        return (
          <DropdownQuestion
            id={id}
            label={label}
            value={value}
            options={options}
            handleChange={handleChange}
            disable={disable}
            required={required}
          />
        );
      default:
        return <div>Error</div>;
    }
  };
  return <React.Fragment>{getBody()}</React.Fragment>;
}

function InputQuestion(props) {
  const { label, id, value, disable, required, multiline, errorMessage } =
    props;

  const handleChange = (event) => {
    props.handleChange(event.target);
  };
  return (
    <React.Fragment>
      {props.label.length > 15 ? (
        <React.Fragment>
          <Typography variant="subtitle1">{label}</Typography>
          <TextField
            id={id}
            key={id}
            name="value"
            value={value}
            fullWidth
            variant="outlined"
            onChange={handleChange}
            color="secondary"
            disabled={disable}
            multiline={multiline}
            rowsMax={4}
            required={required}
            error={required && value === ""}
            helperText={errorMessage}
          ></TextField>
        </React.Fragment>
      ) : (
        <TextField
          id={id}
          key={id}
          label={label}
          name="value"
          value={value}
          fullWidth
          variant="outlined"
          onChange={handleChange}
          color="secondary"
          disabled={disable}
          multiline={multiline}
          rowsMax={4}
          required={required}
          error={required && value === ""}
          helperText={errorMessage}
        ></TextField>
      )}
    </React.Fragment>
  );
}
function DecisionQuestion(props) {
  const handleChange = (event) => {
    props.handleChange(event.target);
  };
  return (
    <React.Fragment>
      <FormControl
        id={props.id}
        component="fieldset"
        color="primary"
        disabled={props.disable}
      >
        <FormLabel component="legend">{props.label}</FormLabel>
        <RadioGroup
          aria-label="decision_question"
          name="value"
          value={props.value}
          onChange={handleChange}
        >
          {Object.keys(QUESTION_DECISION_OPTIONS).map((key, index) => {
            return (
              <FormControlLabel
                key={index}
                value={QUESTION_DECISION_OPTIONS[key].value}
                control={<Radio />}
                label={getLabelText(
                  props.pageText,
                  QUESTION_DECISION_OPTIONS[key].label
                )}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <Fade
        in={props.showJustificationWith === props.value}
        unmountOnExit
        mountOnEnter
      >
        <TextField
          name="justificationValue"
          multiline
          value={props.justificationValue}
          placeholder={props.justificationPlaceholder}
          onChange={handleChange}
          fullWidth
          disabled={props.disable}
        ></TextField>
      </Fade>
    </React.Fragment>
  );
}
function DropdownQuestion(props) {
  const { id, value, label, options, disable, required } = props;
  const handleChange = (event) => {
    props.handleChange(event.target);
  };
  return (
    <FormControl variant="outlined" disabled={disable} fullWidth>
      <InputLabel id={id + "label"}>{label}</InputLabel>
      <Select
        labelId={id + "label"}
        id={id}
        value={value}
        onChange={handleChange}
        label={label}
        name="value"
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
