import {
  Button,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import Question from "../../../components/Question/Question";
import SendIcon from "@material-ui/icons/Send";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Fade } from "react-reveal";
import { connect } from "react-redux";
import { getLabelText, getPageText } from "../../../_helpers";
import { PAGE_TEXT_KEYS } from "../../../_const";

const pageTextKey = "QUESTIONS";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 20,
  },
  paperRoot: {
    padding: theme.spacing(2),
  },
  paperHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    marginTop: -theme.spacing(2),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  questionRoot: {
    marginTop: theme.spacing(2),
  },
  buttonSuccess: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
  buttonWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
function ReviewView(props) {
  const { questions, sending, pageText, disableSubmit } = props;

  const classes = useStyles();

  const handleQuestionChange = (
    groupIndex,
    subGroupIndex,
    questionIndex,
    question
  ) => {
    props.onChange(groupIndex, subGroupIndex, questionIndex, question);
  };
  return (
    <section id="questions-review-view" className={classes.root}>
      <Grid container direction="column" spacing={3}>
        {questions.map((group, groupIndex) => {
          return (
            <Grid key={group.group.id} item xs={12}>
              <Fade bottom>
                <Paper elevation={3} className={classes.paperRoot}>
                  {group.group.displayLabel && (
                    <Typography
                      className={classes.paperHeader}
                      variant="h5"
                      align="center"
                    >
                      {getLabelText(pageText, group.group.title)}
                    </Typography>
                  )}
                  <Grid container direction="column" spacing={2}>
                    {group.subgroups.map((subgroup, subGroupIndex) => {
                      return (
                        <Grid key={subgroup.subgroup.id} item xs={12}>
                          {subgroup.subgroup.label && (
                            <Typography
                              variant="h6"
                              align="center"
                              color="secondary"
                            >
                              {getLabelText(pageText, subgroup.subgroup.label)}
                            </Typography>
                          )}
                          <Container className={classes.questionRoot}>
                            <Grid container spacing={2}>
                              {subgroup.questions.map(
                                (question, questionIndex) => {
                                  return (
                                    <Grid
                                      key={question.id}
                                      item
                                      xs={question.col || 12}
                                    >
                                      <Question
                                        pageText={pageText}
                                        disable={sending}
                                        question={question}
                                        onChange={(question) =>
                                          handleQuestionChange(
                                            groupIndex,
                                            subGroupIndex,
                                            questionIndex,
                                            question
                                          )
                                        }
                                      />
                                    </Grid>
                                  );
                                }
                              )}
                              {subgroup.showWarning && (
                                <Grid item xs={12}>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {getLabelText(
                                      pageText,
                                      PAGE_TEXT_KEYS.RESPONSE_MESSAGE
                                    )}
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Container>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Paper>
              </Fade>
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={3} justify="flex-end">
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={props.onReset}
            endIcon={<HighlightOffIcon />}
            disabled={sending}
          >
            {getLabelText(pageText, PAGE_TEXT_KEYS.RESET_BUTTON)}
          </Button>
        </Grid>
        <Grid item>
          <div className={classes.buttonWrapper}>
            <Button
              color="primary"
              variant="contained"
              onClick={props.onSend}
              endIcon={<SendIcon />}
              disabled={sending || disableSubmit}
            >
              {getLabelText(pageText, PAGE_TEXT_KEYS.SEND_BUTTON)}
            </Button>
            {sending && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </Grid>
      </Grid>
    </section>
  );
}

function mapStateToProps(state) {
  return { pageText: getPageText(state, pageTextKey) };
}

const connectedReviewView = connect(mapStateToProps)(ReviewView);
export { connectedReviewView as ReviewView };
