import React from "react";
import { makeStyles, Typography, useMediaQuery, useTheme,CircularProgress,  Container, Grid, Paper, Button, TextField } from "@material-ui/core";
import { Fade } from "react-reveal";
import Parallax from "../../../components/Parallax/Parallax";
import { IMAGES_URL } from "../../../_const";
import IconDivider from "../../../components/IconDivider/IconDivider";
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import { getLabelText, getPageText, httpServices } from "../../../_helpers";
import SendIcon from '@material-ui/icons/Send';
import { PAGE_TEXT_KEYS } from "../../../_const";
import {connect} from "react-redux";

const pageTextKey = PAGE_TEXT_KEYS.SERVICES;

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(3)
    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    card: {
        position: "relative",
        minWidth: 200,
        minHeight: 200,
        backgroundColor: theme.palette.primary.light,
        overflow: "hidden"
    },
    cardIcon: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 1
    },
    cardText: {
        position: "absolute",
        bottom: 10,
        left: 10,
        zIndex: 2
    },
    miamiImg: {
        width: "100%",
        height: "100%",
        maxWidth: theme.breakpoints.values["sm"]
    }
}));

function PersonBody(props) {
    const classes = useStyles();
    const theme = useTheme();
    let isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const iconSize = 150;
    const services = [
        { id: 0, text: PAGE_TEXT_KEYS.PERSON_0, icon: (<SupervisorAccountIcon style={{ fontSize: iconSize }} />) },
        { id: 1, text: PAGE_TEXT_KEYS.PERSON_1, icon: (<FitnessCenterIcon style={{ fontSize: iconSize }} />) },
        { id: 2, text: PAGE_TEXT_KEYS.PERSON_2, icon: (<PersonPinCircleIcon style={{ fontSize: iconSize }} />) },
        { id: 3, text: PAGE_TEXT_KEYS.PERSON_3, icon: (<FastfoodIcon style={{ fontSize: iconSize }} />) },
        { id: 4, text: PAGE_TEXT_KEYS.PERSON_4, icon: (<InsertChartIcon style={{ fontSize: iconSize }} />) },
        { id: 5, text: PAGE_TEXT_KEYS.PERSON_5, icon: (<LockOpenIcon style={{ fontSize: iconSize }} />) }
    ];
    const [liveInMiami, setLiveInMiami] = React.useState(false);
    const [contactData, setContactData] = React.useState(
        {
            email: "",
            phone: ""
        }
    );
    const [sendingEmail, setSendingEmail] = React.useState(false);
    const [emailSent, setEmailSent] = React.useState(false);

    const handleChange = (event) => {
        const { value, name } = event.target;
        setContactData({ ...contactData, [name]: value });
    }
    const sendEmail = () => {
            setSendingEmail(true);
            const htmlBody = `<h1>Request Info about Personal Training</h1>
            <p><b>Email: </b> ${contactData.email}</p>
            <p><b>Phone: </b> ${contactData.phone}</p>`;
            httpServices.post("https://us-central1-everystephurt.cloudfunctions.net/sendMail", { emailSubject: "Request Info for (In Person Training)", emailBody: htmlBody })
                .then(
                    response => {
                        setSendingEmail(false);
                        setEmailSent(true);
                    },
                    error => {
                        console.log(error);
                        setSendingEmail(false);
                    }
                );
    }
    return (
        <section id="personalTrainerBody" className={classes.root}>
            <Parallax image={IMAGES_URL.SERVICES.PERSONAL}
                height={500}
                overlay={true}
                overlayColor="secondary"
            >
                <Typography variant={isMobile ? "h4" : "h1"} align="center" className={classes.title} color="primary">
                    {getLabelText(props,PAGE_TEXT_KEYS.PERSONAL_TRAINING_LABEL)}
                </Typography>
            </Parallax>
            <IconDivider
                color="primary"
                avatarSize={100}
            >
                <SupervisorAccountIcon style={{ fontSize: 70 }} />
            </IconDivider>
            <Container>
                <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={6}>
                        <Fade left>
                            <Typography variant={isMobile ? "body1" : "h6"} color="textSecondary">
                               {getLabelText(props,PAGE_TEXT_KEYS.PERSON_DESCRIPTION)}
                        </Typography>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                        {services.map((service, index) => {
                            return (
                                <Grid key={service.id} item xs={12} sm={6} md={4}>
                                    <Fade top delay={theme.transitions.duration.enteringScreen * index}>
                                        <Paper className={classes.card} elevation={3}>
                                            <Typography className={classes.cardText} variant="h6" color="secondary">
                                                {getLabelText(props,service.text)}
                                            </Typography>
                                            <div className={classes.cardIcon}>{service.icon}</div>
                                        </Paper>
                                    </Fade>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid item xs={12} sm={8} container spacing={1} justify="center">
                        <Grid item xs={12} sm={8}>
                            <Fade left>
                                <img className={classes.miamiImg} src={IMAGES_URL.SERVICES.PERSONAL_MIAMI} alt="Miami Map" />
                            </Fade>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {!emailSent ? (
                                <Grid container justify="flex-end" alignItems="center" className={classes.full}>
                                    <Grid item xs={12} container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Fade top>
                                                <Typography variant={isMobile ? "h5" : "h4"} align="center" color="primary">{getLabelText(props,PAGE_TEXT_KEYS.PERSON_CONTACT)}</Typography>
                                            </Fade>
                                        </Grid>
                                        <Grid item xs={12} container justify="center">
                                            <Button disabled={liveInMiami} variant="contained" color="primary" onClick={() => setLiveInMiami(true)}>{getLabelText(props,PAGE_TEXT_KEYS.PERSON_CONTACT_BUTTON_1)}</Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Fade top when={liveInMiami} mountOnEnter cascade>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" color="secondary" paragraph>
                                                        {getLabelText(props,PAGE_TEXT_KEYS.PERSON_CONTACT_TITLE)}
                                                        </Typography>
                                                        <Typography variant="subtitle1" color="secondary" paragraph>
                                                        {getLabelText(props,PAGE_TEXT_KEYS.PERSON_CONTACT_BODY)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            color="primary"
                                                            label="Email"
                                                            name="email"
                                                            value={contactData.email}
                                                            onChange={handleChange}
                                                            type="email"
                                                        ></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} container justify="center">
                                                        <Typography variant="overline" align="center" color="primary">OR</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            color="primary"
                                                            label={getLabelText(props,PAGE_TEXT_KEYS.PERSON_CONTACT_PHONE_NUMBER)}
                                                            name="phone"
                                                            value={contactData.phone}
                                                            onChange={handleChange}
                                                        ></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} container justify="flex-end">
                                                        <Button
                                                            disabled={sendingEmail || (contactData.email === "" && contactData.phone === "")}
                                                            onClick={sendEmail}
                                                            variant="contained"
                                                            color="primary"
                                                            endIcon={<SendIcon />}>
                                                            {sendingEmail ? <CircularProgress size={24} color="secondary" /> : getLabelText(props,PAGE_TEXT_KEYS.PERSON_CONTACT_BUTTON_2)}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Fade>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                    <Grid container justify="flex-end" alignItems="center" className={classes.full}>
                                        <Grid item xs={12} container direction="column" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h3" align="center" color="primary">{getLabelText(props,PAGE_TEXT_KEYS.PERSON_CONTACT_SUCCESS_TITLE)}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h5" align="center" color="primary">{getLabelText(props,PAGE_TEXT_KEYS.PERSON_CONTACT_SUCCESS_BODY)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}

function mapStateToProps(state) {
    return getPageText(state,pageTextKey);
}

const connectedPersonBody = connect(mapStateToProps)(PersonBody);
export  { connectedPersonBody as PersonBody };