import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from "@material-ui/core";
import { Fade } from 'react-reveal';
import { IMAGES_URL, NAVIGATION_LINKS, PAGE_TEXT_KEYS } from '../../_const';
import {connect} from "react-redux";
import { getLabelText, getPageText } from '../../_helpers';

const pageTextKey = "ABOUT_ME";
const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(3),
        backgroundColor: theme.palette.primary.dark
    },
    documentaryWrapper: {
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(6),
        display: "flex",
        justifyContent: "center",
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
    },
    aboutMeRoot: {
        position: "relative",
        minHeight: 300,
        display: "flex",
        justifyContent: "center",
        alignItems: "right",
        padding: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            background: "none"
        },
    },
    aboutMeText: {
        position: "absolute",
        top: 10,
        left: 30,
        width: "50%",
        [theme.breakpoints.down('xs')]: {
            position: "static"
        },
    },
    aboutMeImage: {
        width: "100%",
        height: "100%"
    }
}));
function AboutMe(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    
    return (
        <section id={NAVIGATION_LINKS.ABOUT_ME} className={classes.root}>
            {/* <div className={classes.aboutMeRoot}>
                <div className={classes.aboutMeText}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Fade left>
                                <Typography variant="body1" color="secondary">
                                    Here we can talk about you. This section is to introduce yourself. Your history, and past. etc
                                </Typography>
                            </Fade>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Fade left>
                                <Typography variant="body1" color="secondary">
                                    We can even add images to the description
                                </Typography>
                            </Fade>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Fade rigth>
                                <img className={classes.aboutMeImage} alt="Sample" src="https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Fimages%2FDSC08271.JPG?alt=media&token=183c9224-9698-4572-b076-6b1592d79c18" />
                            </Fade>
                        </Grid>
                    </Grid>
                </div>
            </div> */}
            <Fade top>
                <Typography variant={!isMobile ? "h1" : "h4"} color="secondary" align="center">{getLabelText(props,PAGE_TEXT_KEYS.ABOUT_ME_TITLE_LABEL)}</Typography>
            </Fade>
            <Fade bottom>
                <div className={classes.documentaryWrapper}>
                    <iframe title="EveryStepHurtDocumentary" width="560" height="315" src={IMAGES_URL.ABOUT_ME.DOCUMENTATY} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </Fade>
        </section>
    );
}

function mapStateToProps(state) {
    return getPageText(state,pageTextKey);
}

const connectedLanguageButton = connect(mapStateToProps)(AboutMe);
export  { connectedLanguageButton as AboutMe };
