export const QUESTIONS_STEPS = {
  STEP_1: "Introduction",
  STEP_2: "Question",
  STEP_3: "Conclusion",
  STEP_4: "Error",
};
export const QUESTION_TYPE = {
  INPUT: "INPUT",
  YESORNO: "YESORNO",
  YESORNOWITHJUSTIFICATION: "YESORNOWITHJUSTIFICATION",
  DROPDOWN: "DROPDOWN",
};
export const QUESTION_GROUP = {
  GENERAL: { id: "general", displayLabel: true, title: "QUESTION_GENERAL" },
  PHYSICAL_ACTIVITY: {
    id: "physicalActivity",
    displayLabel: true,
    title: "QUESTION_PHYSICAL_ACTIVITY",
  },
  MEDICAL: { id: "medical", displayLabel: true, title: "QUESTION_MEDICAL" },
};
export const QUESTION_SUBGROUP = {
  GENERAL: { id: "GENERAL", label: "" },
  QUESTIONS: { id: "QUESTIONS", label: "QUESTION_QUESTIONS" },
  OCCUPATIONAL: { id: "OCCUPATIONAL", label: "QUESTION_OCCUPATIONAL" },
  RECREATIONAL: { id: "RECREATIONAL", label: "QUESTION_RECREATIONAL" },
  MEDICAL: { id: "MEDICAL", label: "QUESTION_SUB_GROUP_MEDICAL" },
  SMARTGOALS: { id: "SMARTGOALS", label: "QUESTION_SMARTGOALS" },
};
export const QUESTION_DECISION_OPTIONS = {
  YES: { value: "yes", label: "YES_OPTION" },
  NO: { value: "no", label: "NO_OPTION" },
};
export const QUESTIONS = [
  {
    group: QUESTION_GROUP.GENERAL,
    subgroups: [
      {
        subgroup: QUESTION_SUBGROUP.GENERAL,
        questions: [
          {
            id: "name",
            label: "NAME",
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: true,
            errorMessage: "Please enter your name",
            col: 12,
          },

          {
            id: "age",
            label: "AGE",
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "Please enter your age",
            col: 6,
          },
          {
            id: "sex",
            label: "SEX",
            type: QUESTION_TYPE.DROPDOWN,
            value: "",
            required: false,
            errorMessage: "Please enter your sex",
            col: 6,
            options: "SEX_OPTIONS",
          },

          {
            id: "height",
            label: "HEIGHT",
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "Please enter your height",
            col: 6,
          },
          {
            id: "weight",
            label: "WEIGHT",
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "Please enter your weight",
            col: 6,
          },

          {
            id: "address",
            label: "ADDRESS",
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "",
            col: 12,
          },
          {
            id: "city",
            label: "CITY",
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "",
            col: 4,
          },
          {
            id: "state",
            label: "STATE",
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "",
            col: 4,
          },
          {
            id: "zipCode",
            label: "ZIP_CODE",
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "",
            col: 4,
          },

          {
            id: "phone",
            label: "PHONE",
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: true,
            errorMessage: "Please enter your phone number",
            questionFormat: "phone",
            col: 6,
          },
          {
            id: "email",
            label: "EMAIL",
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: true,
            errorMessage: "Please enter your email",
            col: 12,
          },

          {
            id: "heardaboutme",
            label: "HEARD_ABOUT_ME",
            type: QUESTION_TYPE.DROPDOWN,
            value: "",
            required: true,
            errorMessage: "Please enter your sex",
            col: 12,
            options: "HEARD_ABOUT_ME_OPTIONS",
          },
        ],
      },
    ],
  },
  {
    group: QUESTION_GROUP.PHYSICAL_ACTIVITY,
    subgroups: [
      {
        subgroup: QUESTION_SUBGROUP.QUESTIONS,
        showWarning: false,
        haveWarning: true,
        showWarningWhen: QUESTION_DECISION_OPTIONS.YES.value,
        questions: [
          {
            id: "hearthCondition",
            label: `HEARTH_CONDITION`,
            type: QUESTION_TYPE.YESORNO,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            responseMessage: "RESPONSE_MESSAGE",
          },
          {
            id: "chestPain",
            label: `CHEST_PAIN`,
            type: QUESTION_TYPE.YESORNO,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            responseMessage: `RESPONSE_MESSAGE`,
          },
          {
            id: "pastChestPain",
            label: `PAST_CHEST_PAIN`,
            type: QUESTION_TYPE.YESORNO,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            responseMessage: `RESPONSE_MESSAGE`,
          },
          {
            id: "loseBalance",
            label: `LOSE_BALANCE`,
            type: QUESTION_TYPE.YESORNO,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            responseMessage: `RESPONSE_MESSAGE`,
          },
          {
            id: "boneProblem",
            label: `BONE_PROBLEM`,
            type: QUESTION_TYPE.YESORNO,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            responseMessage: `RESPONSE_MESSAGE`,
          },
          {
            id: "prescribingMedication",
            label: `PRESCRIBING_MEDICATION`,
            type: QUESTION_TYPE.YESORNO,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            responseMessage: `RESPONSE_MESSAGE`,
          },
          {
            id: "otherReason",
            label: `OTHER_REASON`,
            type: QUESTION_TYPE.YESORNO,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            responseMessage: `RESPONSE_MESSAGE`,
          },
        ],
      },
    ],
  },
  {
    group: QUESTION_GROUP.MEDICAL,
    subgroups: [
      {
        subgroup: QUESTION_SUBGROUP.OCCUPATIONAL,
        questions: [
          {
            id: "currentPosition",
            label: `CURRENT_POSITION`,
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "CURRENT_POSITION_ERROR",
          },
          {
            id: "occupationMovement",
            label: `OCCUPATION_MOVEMENT`,
            type: QUESTION_TYPE.YESORNO,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
          },
          {
            id: "repetitiveMovement",
            label: `REPETITIVE_MOVEMENT`,
            type: QUESTION_TYPE.YESORNOWITHJUSTIFICATION,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            showJustificationWith: QUESTION_DECISION_OPTIONS.YES.value,
            justificationValue: "",
            justificationPlaceholder: "PLEASE_EXPLAIN",
          },
          {
            id: "heelShoes",
            label: `HEEL_SHOES`,
            type: QUESTION_TYPE.YESORNO,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
          },
          {
            id: "anxiety",
            label: `ANXIETY`,
            type: QUESTION_TYPE.YESORNO,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
          },
        ],
      },
      {
        subgroup: QUESTION_SUBGROUP.RECREATIONAL,
        questions: [
          {
            id: "recreationalActivities",
            label: `RECREATIONAL_ACTIVITIES`,
            type: QUESTION_TYPE.YESORNOWITHJUSTIFICATION,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            showJustificationWith: QUESTION_DECISION_OPTIONS.YES.value,
            justificationValue: "",
            justificationPlaceholder: "PLEASE_EXPLAIN",
          },
          {
            id: "hobbies",
            label: `HOBBIES`,
            type: QUESTION_TYPE.YESORNOWITHJUSTIFICATION,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            showJustificationWith: QUESTION_DECISION_OPTIONS.YES.value,
            justificationValue: "",
            justificationPlaceholder: "PLEASE_EXPLAIN",
          },
        ],
      },
      {
        subgroup: QUESTION_SUBGROUP.MEDICAL,
        questions: [
          {
            id: "previousInjuries",
            label: `PREVIOUS_INJURIES`,
            type: QUESTION_TYPE.YESORNOWITHJUSTIFICATION,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            showJustificationWith: QUESTION_DECISION_OPTIONS.YES.value,
            justificationValue: "",
            justificationPlaceholder: "PLEASE_EXPLAIN",
          },
          {
            id: "surgeries?",
            label: `SURGERIES`,
            type: QUESTION_TYPE.YESORNOWITHJUSTIFICATION,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            showJustificationWith: QUESTION_DECISION_OPTIONS.YES.value,
            justificationValue: "",
            justificationPlaceholder: "PLEASE_EXPLAIN",
          },
          {
            id: "chronicDisease",
            label: `CHRONIC_DISEASE`,
            type: QUESTION_TYPE.YESORNOWITHJUSTIFICATION,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            showJustificationWith: QUESTION_DECISION_OPTIONS.YES.value,
            justificationValue: "",
            justificationPlaceholder: "PLEASE_EXPLAIN",
          },
          {
            id: "medication",
            label: `MEDICATION`,
            type: QUESTION_TYPE.YESORNOWITHJUSTIFICATION,
            value: "",
            required: false,
            errorMessage: "YES_NO_ERROR_MESSAGE",
            showJustificationWith: QUESTION_DECISION_OPTIONS.YES.value,
            justificationValue: "",
            justificationPlaceholder: "PLEASE_EXPLAIN",
          },
        ],
      },
      {
        subgroup: QUESTION_SUBGROUP.SMARTGOALS,
        questions: [
          {
            id: "idealGoal",
            label: `IDEAL_GOAL`,
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "Please enter your current job position",
            multiline: true,
          },
          {
            id: "gym",
            label: `GYM`,
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "",
            multiline: true,
          },
          {
            id: "workoutDays",
            label: `WORKOUT_DAYS`,
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "",
            multiline: false,
          },
          {
            id: "healthyFoodIntake",
            label: `HEALTHY_FOOD_INTAKE`,
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "",
            multiline: true,
          },
          {
            id: "lastTimeWorkout",
            label: `LAST_TIME_WORKOUT`,
            type: QUESTION_TYPE.INPUT,
            value: "",
            required: false,
            errorMessage: "",
            multiline: true,
          },
        ],
      },
    ],
  },
];
