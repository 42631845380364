import React from "react";
import { makeStyles, Typography, useMediaQuery, useTheme, Container, Grid, Paper, Hidden } from "@material-ui/core";
import { Fade, Zoom } from "react-reveal";
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import Parallax from "../../../components/Parallax/Parallax";
import { IMAGES_URL, NAVIGATION_LINKS, QUESTIONS_STEPS } from "../../../_const";
import IconDivider from "../../../components/IconDivider/IconDivider";
import { StartQuestions } from "../../Questions/Start/StartQuestions";
import { useHistory } from "react-router-dom";
import {connect} from "react-redux";
import { getLabelText, getPageText } from "../../../_helpers";

const pageTextKey = "SERVICES";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(3)
    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    cardWrapper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3)
    },
    card: {
        width: 150,
        minHeight: 150,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
        borderRadius: 50,
        padding: theme.spacing(2),
        //clipPath: 'polygon(50% 11%, 100% 0, 100% 75%, 50% 100%, 0 75%, 0% 0%)'
    },
    cardText: {
        textTransform: "uppercase"
    },
    img: {
        width: "100%",
        height: "100%"
    },
    imagePaper: {
        padding: theme.spacing(2)
    },
    contactUs: {
        background: `url(${IMAGES_URL.CONTACT.BACKGROUND})`,
        backgroundSize: "cover",
        padding: theme.spacing(6),
        margin: theme.spacing(2)
    }
}));

function OnlineBody(props) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    let isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const iconSize = 60;
    const services = [
        { id: 0, text: "ONLINE_0", icon: (<PhoneIphoneIcon style={{ fontSize: iconSize }} />) },
        { id: 1, text: "ONLINE_1", icon: (<AccessTimeIcon style={{ fontSize: iconSize }} />) },
        { id: 2, text: "ONLINE_2", icon: (<DateRangeIcon style={{ fontSize: iconSize }} />) },
        { id: 3, text: "ONLINE_3", icon: (<ImageSearchIcon style={{ fontSize: iconSize }} />) }
    ];

    return (
        <section id="onlineBody" className={classes.root}>
            <Parallax image={IMAGES_URL.SERVICES.ONLINE}
                height={"100vh"}
                overlay={true}
                overlayColor="secondary"
            >
                <Typography variant={isMobile ? "h4" : "h1"} align="center" className={classes.title} color="primary">
                    {getLabelText(props,"ONLINE_LABEL")}
                </Typography>
                <Container className={classes.root} maxWidth="lg">
                    <Grid className={classes.cardWrapper} container spacing={2}>
                        {services.map((service, index) => {
                            return (
                                <Grid key={service.id} item xs={12} sm={6} md container justify="center">
                                    <Fade top delay={index * 500}>
                                        <Paper elevation={3} className={classes.card}>
                                            <Grid container justify="center" direction="column">
                                                <Grid item xs={12}>
                                                    {service.icon}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.cardText} variant="button">
                                                        {getLabelText(props,service.text)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Fade>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            </Parallax>
            <IconDivider
                color="primary"
                avatarSize={100}
            >
                <PhoneIphoneIcon style={{ fontSize: 70 }} />
            </IconDivider>
            <Container maxWidth="lg">
                <Grid container className={classes.cardWrapper} spacing={2} direction="row-reverse">
                    <Grid item xs={12}>
                        <Container maxWidth="sm">
                            <Fade right>
                                <Typography variant={isMobile ? "body1" : "h6"} color="textSecondary">
                                    {getLabelText(props,"ONLINE_DESCRIPTION")}
                                </Typography>
                            </Fade>
                        </Container>
                    </Grid>
                    <Grid item xs={12} sm={6} container direction="row" justify="space-evenly" spacing={1}>
                        <Grid item xs={12} sm={5}>
                            <Zoom>
                                <Paper className={classes.imagePaper} variant="outlined" elevation={3}>
                                    <img className={classes.img} src={IMAGES_URL.SERVICES.ONLINE_SAMPLE_1} alt="excersi library gif" />
                                </Paper>
                            </Zoom>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Zoom>
                                <Paper className={classes.imagePaper} variant="outlined" elevation={3}>
                                    <img className={classes.img} src={IMAGES_URL.SERVICES.ONLINE_SAMPLE_2} alt="excersi library gif" />
                                </Paper>
                            </Zoom>
                        </Grid>
                        <Grid item xs={12}>
                            <Zoom>
                                <Paper className={classes.contactUs} elevation={3}>
                                    <StartQuestions handleStartClick={() => history.push(`/${NAVIGATION_LINKS.QUESTIONS}/${QUESTIONS_STEPS.STEP_2}`)} />
                                </Paper>
                            </Zoom>
                        </Grid>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item xs={12} sm={6} style={{ position: "relative" }}>
                            <Fade bottom delay={100}>
                                <img
                                    className={classes.img}
                                    style={{
                                        position: "absolute",
                                        bottom: -50,
                                        height: 600
                                    }}
                                    src={IMAGES_URL.SERVICES.ONLINE_MOBILE_APP}
                                    alt="phone promotion" />
                            </Fade>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </section>
    )
}

function mapStateToProps(state) {
    return getPageText(state,pageTextKey);
}

const connectedOnlineBody = connect(mapStateToProps)(OnlineBody);
export  { connectedOnlineBody as OnlineBody };