import { Container, Fade, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import {
  IMAGES_URL,
  PAGE_TEXT_KEYS,
  QUESTIONS,
  QUESTIONS_STEPS,
  QUESTION_TYPE,
} from "../../_const";
import { NAVIGATION_LINKS } from "../../_const/navigation";
import deepCopy, {
  getLabelText,
  getPageText,
  httpServices,
} from "../../_helpers";
import { ReviewView } from "./Review/Review";
import { StartQuestions } from "./Start/StartQuestions";
import { connect } from "react-redux";

const pageTextKey = "QUESTIONS";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    //height: "100vh"
    paddingTop: 90,
    paddingBottom: 50,
  },
  background: {
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    position: "absolute",
    top: 150,
    textAlign: "center",
  },
  subtitle: {
    position: "absolute",
    top: 250,
  },
  question: {
    position: "absolute",
    top: 350,
  },
  actionButtons: {
    position: "absolute",
    bottom: 10,
  },
  reviewQuestionsRoot: {
    paddingTop: 150,
    overflow: "auto",
  },
  logo: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

function Questions(props) {
  const classes = useStyles();
  let { step = QUESTIONS_STEPS.STEP_1 } = useParams();
  const [activeStep, setActiveStep] = React.useState(step);
  const [questions, setQuestions] = React.useState(deepCopy(QUESTIONS));
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [invalidQuestions, setInvalidQuestions] = React.useState(true);
  const [navBarHeigth, setNavBarHeigth] = React.useState(0);
  const handleQuestionChange = (
    groupIndex,
    subGroupIndex,
    questionIndex,
    question
  ) => {
    let temp = Object.assign([], questions);
    if (temp[groupIndex].subgroups[subGroupIndex].haveWarning) {
      temp[groupIndex].subgroups[subGroupIndex].showWarning =
        temp[groupIndex].subgroups[subGroupIndex].questions.filter(
          (q) =>
            q.value ===
            temp[groupIndex].subgroups[subGroupIndex].showWarningWhen
        ).length > 0;
    }
    temp[groupIndex].subgroups[subGroupIndex].questions[questionIndex] =
      question;
    setQuestions(temp);
    setInvalidQuestions(!validateQuestions());
  };
  const resetQuestions = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setQuestions(deepCopy(QUESTIONS));
  };

  const validateQuestions = () => {
    let _questions = [];
    questions.forEach((group) => {
      group.subgroups.forEach((subgroup) => {
        _questions = _questions.concat(subgroup.questions);
      });
    });
    return !_questions.some((x) => x.required && !x.value);
  };

  const submit = () => {
    if (validateQuestions()) {
      let htmlBody = `<p>Date: <b>${new Date().toLocaleDateString(
        "en-US"
      )}</b></p>`;
      questions.forEach((group) => {
        if (group.group.displayLabel) {
          htmlBody = htmlBody.concat(`<h5>${group.group.title}</h5>`);
        }
        group.subgroups.forEach((subgroup) => {
          if (subgroup.subgroup.label) {
            htmlBody = htmlBody.concat(`<h6>${subgroup.subgroup.label}</h6>`);
          }
          subgroup.questions.forEach((question) => {
            htmlBody = htmlBody.concat(
              `<p><b>${question.label}:</b>  ${question.value}</p>`
            );
            if (question.type === QUESTION_TYPE.YESORNOWITHJUSTIFICATION) {
              htmlBody = htmlBody.concat(
                `<p><b>Explanation: </b>${question.justificationValue}</p>`
              );
            }
          });
        });
        htmlBody = htmlBody.concat(`<br>`);
        htmlBody = htmlBody.concat(`<hr>`);
        htmlBody = htmlBody.concat(`<br>`);
      });
      setSendingEmail(true);
      httpServices
        .post("https://us-central1-everystephurt.cloudfunctions.net/sendMail", {
          emailSubject: "Data Collection Sheet Completed",
          emailBody: htmlBody,
        })
        .then(
          (response) => {
            setSendingEmail(false);
            const head = document.querySelector("head");
            const script = document.createElement("script");
            script.setAttribute(
              "src",
              "https://assets.calendly.com/assets/external/widget.js"
            );
            head.appendChild(script);
            setActiveStep(QUESTIONS_STEPS.STEP_3);
          },
          (error) => {
            setSendingEmail(false);
            setActiveStep(QUESTIONS_STEPS.STEP_4);
          }
        );
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setPaddingTop();
  }, [navBarHeigth]);

  const setPaddingTop = () => {
    if (document.getElementById("transparent-navbar")) {
      setNavBarHeigth(
        document.getElementById("transparent-navbar").getBoundingClientRect()
          .height
      );
    } else {
      setNavBarHeigth(
        document.getElementById("transparent-navbar").getBoundingClientRect()
          .height
      );
    }
  };
  return (
    <section
      id={NAVIGATION_LINKS.QUESTIONS}
      className={classes.background}
      style={{
        height: "100%",
        marginBottom: "0%",
        paddingTop: navBarHeigth,
      }}
    >
      <Container maxWidth="sm" className={classes.root}>
        <img className={classes.logo} src={IMAGES_URL.LOGO} alt="logo" />
        <Fade
          in={activeStep === QUESTIONS_STEPS.STEP_1}
          unmountOnExit
          mountOnEnter
        >
          <StartQuestions
            handleStartClick={() => setActiveStep(QUESTIONS_STEPS.STEP_2)}
          />
        </Fade>
        <Fade
          in={activeStep === QUESTIONS_STEPS.STEP_2}
          mountOnEnter
          unmountOnExit
        >
          <ReviewView
            questions={questions}
            onChange={handleQuestionChange}
            onReset={resetQuestions}
            onSend={submit}
            sending={sendingEmail}
            disableSubmit={invalidQuestions}
          />
        </Fade>
        <Fade
          in={activeStep === QUESTIONS_STEPS.STEP_3}
          mountOnEnter
          unmountOnExit
        >
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="secondary">
              {getLabelText(
                props,
                PAGE_TEXT_KEYS.QUESTION_SUBMIT_SUCCESS_TITTLE
              )}
            </Typography>
            <Typography variant="h6" align="center" color="secondary">
              {getLabelText(props, PAGE_TEXT_KEYS.QUESTION_SUBMIT_SUCCESS_BODY)}
            </Typography>
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/everystephurt/30min"
              style={{
                minWidth: 320,
                height: 630,
              }}
            ></div>
          </Container>
        </Fade>
        <Fade
          in={activeStep === QUESTIONS_STEPS.STEP_4}
          mountOnEnter
          unmountOnExit
        >
          <Container maxWidth="sm">
            <Typography
              variant="h5"
              align="center"
              className={classes.errorText}
            >
              {getLabelText(props, PAGE_TEXT_KEYS.QUESTION_SUBMIT_ERROR_TITLE)}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              className={classes.errorText}
            >
              {getLabelText(props, PAGE_TEXT_KEYS.QUESTION_SUBMIT_ERROR_BODY)}
            </Typography>
          </Container>
        </Fade>
      </Container>
    </section>
  );
}

function mapStateToProps(state) {
  return getPageText(state, pageTextKey);
}

const connectedQuestions = connect(mapStateToProps)(Questions);
export { connectedQuestions as Questions };
