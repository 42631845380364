export * from './http.service';
export * from "./store";
export default function deepCopy (array){
    return JSON.parse(JSON.stringify(array));
}
export  function getPageText (state,pageKey){
    return state.language.pageText[pageKey]
}

export function getLabelText (props,key){
    return props[key];
}