import React from "react";
import './App.css';
import {
  Switch,
  Route,
  BrowserRouter
} from "react-router-dom";
import {AboutMe, Services} from "./pages";
import {Footer} from './components/Footer/Footer';
import {Header} from './components/Header/Header';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Contact from "./pages/Contact/Contact";
import Wrapper from "./components/Wrapper/Wrapper";
import Home from "./pages/Home/Home";
import { NAVIGATION_LINKS } from "./_const";
import { Questions } from "./pages/Questions/Questions";
import {Testimonies} from "./pages/Testimonies/Testimonies";
import {NewsLetter} from "./pages/NewsLetter/NewsLetter";
import SigUpModal from "./components/Modal/Modal";
import { LanguageButton } from "./components/Language/Language";
let _theme = require("./assets/theme.json");
const theme = createMuiTheme(_theme);

const page = (
  <Wrapper>
    <Home></Home>
    <Services></Services>
    <AboutMe></AboutMe>
    <NewsLetter></NewsLetter>
    <Testimonies></Testimonies>
    <Contact></Contact>
  </Wrapper>
)
function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Header></Header>
        <Switch>
          <Route exact path={`/${NAVIGATION_LINKS.QUESTIONS}/:step`} component={Questions} />
          <Route exact path={`/${NAVIGATION_LINKS.QUESTIONS}`} component={Questions} />
          <Route path="/:section">
            {page}
          </Route>
          <Route exact path="/">
            {page}
          </Route>
        </Switch>
        <SigUpModal/>
        <LanguageButton/>
        <Footer></Footer>
      </ThemeProvider>
    </BrowserRouter>
  );
}
export default App;
