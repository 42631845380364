import { Fade, Modal,Backdrop, Paper, makeStyles, IconButton } from "@material-ui/core";
import React from "react";
import { NewsLetter } from "../../pages/NewsLetter/NewsLetter";
import CancelIcon from '@material-ui/icons/Cancel';
const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    paper: {
        position: "relative"
    },
    close: {
        position: "absolute",
        top: 0,
        right: 0
    }
  }));

export default function SigUpModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const modalTime = 300000;
    
    setTimeout((open) => {
        if (!open && !localStorage.getItem("modalShowed")) {
            setOpen(true);
        }
    }, modalTime);
    React.useEffect(()=> {
        if(open && !localStorage.getItem("modalShowed")){
            localStorage.setItem("modalShowed", true);
        }
    },[open])
    const handleClose = () => {
        setOpen(false);
      };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            className={classes.modal}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Paper elevation={3} className={classes.paper}>
                    <IconButton color="secondary" className={classes.close} onClick={handleClose}>
                        <CancelIcon/>
                    </IconButton>
                    <NewsLetter/>
                </Paper>
            </Fade>
        </Modal>
    )
}