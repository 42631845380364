export const PAGE_TEXT_KEYS = {
    HEADER: "HEADER",
    SERVICES:"SERVICES",
    ABOUT_ME:"ABOUT_ME",
    TRANSFORMATIONS: "TRANSFORMATIONS",
    START_TRAINING: "START_TRAINING",
    ONLINE_LABEL:"ONLINE_LABEL",
    NUTRITION_LABEL: "NUTRITION_LABEL",
    PERSONAL_TRAINING_LABEL: "PERSONAL_TRAINING_LABEL",
    ONLINE_0: "ONLINE_0",
    ONLINE_1: "ONLINE_1",
    ONLINE_2: "ONLINE_2",
    ONLINE_3: "ONLINE_3",
    ONLINE_DESCRIPTION: "ONLINE_DESCRIPTION",
    NUTRITION_DESCRIPTION: "NUTRITION_DESCRIPTION",
    NUTRITION_CARD_HEADER: "NUTRITION_CARD_HEADER",
    NUTRITION_0: "NUTRITION_0",
    NUTRITION_1: "NUTRITION_1",
    NUTRITION_2: "NUTRITION_2",
    NUTRITION_CONTACT_TITLE: "NUTRITION_CONTACT_TITLE",
    NUTRITION_CONTACT_BODY: "NUTRITION_CONTACT_BODY",
    NUTRITION_CONTACT_HELPER_TEXT: "NUTRITION_CONTACT_HELPER_TEXT",
    NUTRITION_CONTACT_BUTTON: "NUTRITION_CONTACT_BUTTON",
    NUTRITION_CONTACT_SUCCESS_TITLE: "NUTRITION_CONTACT_SUCCESS_TITLE",
    NUTRITION_CONTACT_SUCCESS_BODY: "NUTRITION_CONTACT_SUCCESS_BODY",
    PERSON_0: "PERSON_0",
    PERSON_1: "PERSON_1",
    PERSON_2: "PERSON_2",
    PERSON_3: "PERSON_3",
    PERSON_4: "PERSON_4",
    PERSON_5: "PERSON_5",
    PERSON_DESCRIPTION: "PERSON_DESCRIPTION",
    PERSON_CONTACT:"PERSON_CONTACT",
    PERSON_CONTACT_BUTTON_1: "PERSON_CONTACT_BUTTON_1",
    PERSON_CONTACT_TITLE:"PERSON_CONTACT_TITLE",
    PERSON_CONTACT_BODY: "PERSON_CONTACT_BODY",
    PERSON_CONTACT_OR: "PERSON_CONTACT_OR",
    PERSON_CONTACT_PHONE_NUMBER:"PERSON_CONTACT_PHONE_NUMBER",
    PERSON_CONTACT_BUTTON_2: "PERSON_CONTACT_BUTTON_2",
    PERSON_CONTACT_SUCCESS_TITLE: "PERSON_CONTACT_SUCCESS_TITLE",
    PERSON_CONTACT_SUCCESS_BODY: "PERSON_CONTACT_SUCCESS_BODY",
    NEWSLETTER: "NEWSLETTER",
    SIGN_UP:"SIGN_UP",
    SIGN_UP_DESCRIPTION:"SIGN_UP_DESCRIPTION",
    HELPER_TEXT:"HELPER_TEXT",
    SUCCESS_MESSAGE_TITLE:"SUCCESS_MESSAGE_TITLE",
    SUCCESS_MESSAGE_BODY:"SUCCESS_MESSAGE_BODY",
    ABOUT_ME_TITLE_LABEL:"ABOUT_ME_TITLE_LABEL",
    TESTIMONIES:"TESTIMONIES",
    TESTIMONIES_TITLE_LABEL:"TESTIMONIES_TITLE_LABEL",
    START_QUESTIONS:"START_QUESTIONS",
    TITLE:"TITLE",
    BODY:"BODY",
    BUTTON:"BUTTON",
    QUESTIONS:"QUESTIONS",
    NAME:"NAME",
    AGE:"AGE",
    SEX:"SEX",
    HEIGHT:"HEIGHT",
    WEIGHT:"WEIGHT",
    ADDRESS:"ADDRESS",
    CITY:"CITY",
    STATE:"STATE",
    ZIP_CODE:"ZIP_CODE",
    PHONE:"PHONE",
    EMAIL:"EMAIL",
    HEARTH_CONDITION:"HEARTH_CONDITION",
    YES_NO_ERROR_MESSAGE:"YES_NO_ERROR_MESSAGE",
    RESPONSE_MESSAGE: "RESPONSE_MESSAGE",
    CHEST_PAIN:"CHEST_PAIN",
    PAST_CHEST_PAIN:"PAST_CHEST_PAIN",
    LOSE_BALANCE:"LOSE_BALANCE",
    BONE_PROBLEM:"BONE_PROBLEM",
    PRESCRIBING_MEDICATION:"PRESCRIBING_MEDICATION",
    OTHER_REASON:"OTHER_REASON",
    CURRENT_POSITION:"CURRENT_POSITION",
    CURRENT_POSITION_ERROR:"CURRENT_POSITION_ERROR",
    OCCUPATION_MOVEMENT:"OCCUPATION_MOVEMENT",
    REPETITIVE_MOVEMENT:"REPETITIVE_MOVEMENT",
    HEEL_SHOES:"HEEL_SHOES",
    ANXIETY:"ANXIETY",
    RECREATIONAL_ACTIVITIES:"RECREATIONAL_ACTIVITIES",
    HOBBIES:"HOBBIES",
    PLEASE_EXPLAIN:"PLEASE_EXPLAIN",
    PREVIOUS_INJURIES:"PREVIOUS_INJURIES",
    SURGERIES:"SURGERIES",
    CHRONIC_DISEASE:"CHRONIC_DISEASE",
    MEDICATION:"MEDICATION",
    IDEAL_GOAL:"IDEAL_GOAL",
    GYM:"GYM",
    WORKOUT_DAYS:"WORKOUT_DAYS",
    HEALTHY_FOOD_INTAKE:"HEALTHY_FOOD_INTAKE",
    LAST_TIME_WORKOUT:"LAST_TIME_WORKOUT",
    SEND_BUTTON: "SEND_BUTTON",
    RESET_BUTTON: "RESET_BUTTON",
    QUESTION_GENERAL:"QUESTION_GENERAL",
    QUESTION_PHYSICAL_ACTIVITY:"QUESTION_PHYSICAL_ACTIVITY",
    QUESTION_MEDICAL:"QUESTION_MEDICAL",
    QUESTION_QUESTIONS:"QUESTION_QUESTIONS",
    QUESTION_OCCUPATIONAL: "QUESTION_OCCUPATIONAL",
    QUESTION_RECREATIONAL:"QUESTION_RECREATIONAL",
    QUESTION_SUB_GROUP_MEDICAL:"QUESTION_SUB_GROUP_MEDICAL",
    QUESTION_SMARTGOALS:"QUESTION_SMARTGOALS",
    YES_OPTION:"YES_OPTION",
    NO_OPTION:"NO_OPTION",
    QUESTION_SUBMIT_SUCCESS_TITTLE:"QUESTION_SUBMIT_SUCCESS_TITTLE",
    QUESTION_SUBMIT_SUCCESS_BODY:"QUESTION_SUBMIT_SUCCESS_BODY",
    QUESTION_SUBMIT_ERROR_TITLE:"QUESTION_SUBMIT_ERROR_TITLE",
    QUESTION_SUBMIT_ERROR_BODY:"QUESTION_SUBMIT_ERROR_BODY"
}