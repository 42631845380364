import React from "react";
import { makeStyles, Typography, useMediaQuery, useTheme, Paper, Container, Grid, List, ListItemText, ListItem, Divider, ListItemAvatar, Avatar, Card, CardHeader, CardMedia, Button, CircularProgress, TextField } from "@material-ui/core";
import { Fade } from "react-reveal";
import IconDivider from "../../../components/IconDivider/IconDivider";
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Parallax from "../../../components/Parallax/Parallax";
import { getLabelText, getPageText, httpServices } from "../../../_helpers";
import SendIcon from '@material-ui/icons/Send';
import { connect } from "react-redux";
import { PAGE_TEXT_KEYS, IMAGES_URL } from "../../../_const";

const pageTextKey = "SERVICES";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(3),
        backgroundColor: theme.palette.secondary.main
    },
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontWeight: "bold",
        textTransform: "uppercase"
    },
    avatar: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.secondary.main,
    },
    img: {
        width: "100%",
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    card: {
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            color: theme.palette.primary.main,
            '& fieldset': {
                borderColor: theme.palette.primary.main,
            }
        },
        '& label': {
            color: theme.palette.primary.main
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
        },
    },
    addEmail: {
        marginTop: theme.spacing(3)
    },
    addEmailCard: {
        backgroundColor: "inherit",
        padding: theme.spacing(3)
    }
}));
function NutritionBody(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [sendingEmail, setSendingEmail] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [emailSent, setEmailSent] = React.useState(false);
    const [notValidEmail, setNotValidEmail] = React.useState(false);
    let isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const handleChange = (event) => {
        const { value } = event.target;
        setEmail(value);
    }
    const sendEmail = () => {
        if (validateEmail()) {
            setSendingEmail(true);
            const htmlBody = `<h1>Nutrition Info required</h1>
            <p><b>Email</b> ${email}</p>`;
            httpServices.post("https://us-central1-everystephurt.cloudfunctions.net/sendMail", { emailSubject: "Request Info for (Nutrition)", emailBody: htmlBody })
                .then(
                    response => {
                        setSendingEmail(false);
                        setEmailSent(true);
                    },
                    error => {
                        console.log(error);
                        setSendingEmail(false);
                    }
                );
        }
    }
    const validateEmail = () => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            setNotValidEmail(false)
            return (true)
        }
        setNotValidEmail(true)
        return (false)
    }
    return (
        <section id="nutritionBody" className={classes.root}>
            <Parallax image={IMAGES_URL.SERVICES.NUTRITION}
                height={500}
                overlay={true}
                overlayColor="primary"
            >
                <Typography variant={isMobile ? "h4" : "h1"} align="center" className={classes.title} color="secondary">
                    {getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_LABEL)}
                </Typography>
            </Parallax>
            <IconDivider
                color="primary"
                avatarSize={100}
            >
                <RestaurantMenuIcon style={{ fontSize: 70 }} />
            </IconDivider>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} container spacing={2}>
                        <Grid item xs={12}>
                            <Fade left>
                                <Typography variant={isMobile ? "body1" : "h6"} color="textSecondary">
                                     {getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_DESCRIPTION)}
                                    </Typography>
                            </Fade>
                        </Grid>
                        <Grid item xs={12}>
                            <Container maxWidth="xs">
                                <Fade top>
                                    <Card className={classes.card}>
                                        <CardHeader title={getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_CARD_HEADER)} />
                                        <CardMedia
                                            className={classes.img}
                                            image={IMAGES_URL.SERVICES.NUTRITION_SAMPLE}
                                            title="Nutrition Pdf Sample"
                                        />
                                    </Card>
                                </Fade>
                            </Container>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} container>
                        <Grid item xs={12}>
                            <List>
                                <Fade top cascade>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className={classes.avatar}>
                                                <PictureAsPdfIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={
                                            <React.Fragment>
                                                {getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_0)}
                                        </React.Fragment>}
                                        ></ListItemText>
                                    </ListItem>
                                    <Divider></Divider>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className={classes.avatar}>
                                                <RestaurantMenuIcon />
                                            </Avatar></ListItemAvatar>
                                        <ListItemText >{getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_1)}</ListItemText>
                                    </ListItem>
                                    <Divider></Divider>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar className={classes.avatar}>
                                                <ListAltIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText>{getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_2)}</ListItemText>
                                    </ListItem>
                                </Fade>
                            </List>
                        </Grid>
                        <Grid item xs={12}>
                            <Container className={classes.addEmail}>
                                <Paper className={classes.addEmailCard} variant="outlined" square >
                                    {!emailSent ? (
                                        <Grid container justify="flex-end" alignItems="center" className={classes.full}>
                                            <Grid item xs={12} container direction="column" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant={isMobile ? "h4" : "h3"} align="center" color="primary">{getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_CONTACT_TITLE)}</Typography>
                                                    <Typography variant="subtitle1" align="center" color="primary">{getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_CONTACT_BODY)}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField className={classes.textField}
                                                        fullWidth
                                                        variant="outlined"
                                                        color="primary"
                                                        label="Email"
                                                        name="email"
                                                        value={email}
                                                        onChange={handleChange}
                                                        type="email"
                                                        error={notValidEmail}
                                                        helperText={notValidEmail && getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_CONTACT_HELPER_TEXT)}
                                                    ></TextField>
                                                </Grid>
                                                <Grid item xs={12} container justify="flex-end">
                                                    <Button
                                                        disabled={sendingEmail}
                                                        onClick={sendEmail}
                                                        variant="contained"
                                                        color="primary"
                                                        endIcon={<SendIcon />}>
                                                        {sendingEmail ? <CircularProgress size={24} color="secondary" /> : getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_CONTACT_BUTTON)}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                            <Grid container justify="flex-end" alignItems="center" className={classes.full}>
                                                <Grid item xs={12} container direction="column" spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h3" align="center" color="primary">{getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_CONTACT_SUCCESS_TITLE)}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5" align="center" color="primary">{getLabelText(props,PAGE_TEXT_KEYS.NUTRITION_CONTACT_SUCCESS_BODY)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                </Paper>
                            </Container>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}


function mapStateToProps(state) {
    return getPageText(state,pageTextKey);
}

const connectedNutritionBody = connect(mapStateToProps)(NutritionBody);
export  { connectedNutritionBody as NutritionBody };