
import {languageConstants} from "../_const";
export const languageActions = {
    change
};

function change(language){
    return dispatch => {
        dispatch(changeLanguage(language));
    };
    function changeLanguage(language){return { type: languageConstants.LANGUAGE_CHANGED,language}}
}