export const NAVIGATION_LINKS = {
    HOME: "home",
    SERVICES: "services",
    NEWSLETTER:"newsletter",
    ABOUT_ME: "about-me",
    TESTIMONIES: "transformations",
    CONTACT: "start-training",
    QUESTIONS: "questionnaire"
}
export const HEADER_NAVIGATION = [
    {
        label: "SERVICES", href: `/${NAVIGATION_LINKS.SERVICES}`, id: NAVIGATION_LINKS.SERVICES
    },
    {
        label: "ABOUT_ME", href: `/${NAVIGATION_LINKS.ABOUT_ME}`, id: NAVIGATION_LINKS.ABOUT_ME
    },
    {
        label: "TRANSFORMATIONS", href: `/${NAVIGATION_LINKS.TESTIMONIES}`, id: NAVIGATION_LINKS.TESTIMONIES
    },
    {
        label: "START_TRAINING", href: `/${NAVIGATION_LINKS.CONTACT}`, id: NAVIGATION_LINKS.CONTACT
    }]