import { makeStyles, Avatar } from "@material-ui/core";
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import React from "react";
import { ENGLISH, LANGUAGES, SPANISH, IMAGES_URL } from "../../_const";
import { connect } from 'react-redux';
import { languageActions } from "../../_actions";
const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        right: 10,
        bottom: 10
    }
}))
function LanguageButton(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [languageSelected, setLanguageSelected] = React.useState(ENGLISH);
    const languageButtons = {
        [ENGLISH]: { icon: <Avatar src={IMAGES_URL.LANGUAGE_BUTTON.ENGLISH} />, name: 'English', id: ENGLISH },
        [SPANISH]: { icon: <Avatar src={IMAGES_URL.LANGUAGE_BUTTON.SPANISH} />, name: 'Español', id: SPANISH }
    };
    const {changeLanguage} = props;
    React.useEffect(() => {
        if (localStorage.getItem("language")) {
            setLanguageSelected(localStorage.getItem("language"));
            changeLanguage(localStorage.getItem("language"));
        }
    }, [languageSelected, changeLanguage]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleLanguageChange = (event) => {
        const { name } = event.currentTarget;
        setLanguageSelected(name);
        setOpen(false);
        localStorage.setItem("language", name);
        props.changeLanguage(name)
    }

    return (
        <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.root}
            icon={
                languageButtons[languageSelected].icon
            }
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction="up"
        >
            {LANGUAGES.map((language) => {
                const { id, icon, name } = languageButtons[language];
                return (
                    <SpeedDialAction
                        key={id}
                        id={id}
                        icon={icon}
                        tooltipTitle={name}
                        name={id}
                        onClick={handleLanguageChange}
                    />
                )
            })}
        </SpeedDial>
    )
}

function mapStateToProps(state) {
    const { language } = state.language;
    return { language }
}

const actionCreators = {
    changeLanguage: languageActions.change
}

const connectedLanguageButton = connect(mapStateToProps, actionCreators)(LanguageButton);
export { connectedLanguageButton as LanguageButton };
