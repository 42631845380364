import {ENGLISH, languageConstants, PAGE_TEXT} from "../_const";

let languageSelected = localStorage.getItem('language');
const initialState = languageSelected ? 
{ language: languageSelected, pageText: PAGE_TEXT[languageSelected] } : 
{ language: ENGLISH, pageText: PAGE_TEXT[ENGLISH]};

export function language(state = initialState, action) {
  switch (action.type) {
    case languageConstants.LANGUAGE_CHANGED:
        const pageText = PAGE_TEXT[action.language];
        return {
            language: action.language,
            pageText: pageText
        }
    default:
      return state
  }
}