
import { ENGLISH_TEXT,SPANISH_TEXT } from "./languages";




export const PAGE_TEXT = {
    ENGLISH : ENGLISH_TEXT,
    SPANISH: SPANISH_TEXT 
}
