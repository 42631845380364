import React from "react";
import { makeStyles, AppBar, Toolbar, Hidden, List, ListItem, ListItemText } from '@material-ui/core';
import SocialMediaList from "../SocialMediaList/SocialMediaList";
import { Zoom } from "react-reveal";
import { HEADER_NAVIGATION, IMAGES_URL } from "../../_const";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getLabelText, getPageText } from "../../_helpers";

const pageTextKey = "HEADER";
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.dark
    },
    grow: {
        flexGrow: 1,
    },
    grow_sm: {
        flexGrow: 0.2,
    },
    logo: {
        height: "100%",
        width: 100,
        margin: theme.spacing(2)
    },
}));
function Footer(props) {
    const classes = useStyles();
    const history = useHistory();
    const handleNavigation = (link) => {
        history.push(link);
    }
    return (
        <footer >
            <AppBar position="static" className={classes.root}>
                <Toolbar>
                    <Hidden xsDown>
                        <Zoom>
                            <img className={classes.logo} src={IMAGES_URL.LOGO} alt="Elite view logo" />
                        </Zoom>
                    </Hidden>
                    <div className={classes.grow_sm} />
                    <List>
                        {HEADER_NAVIGATION.map(link => {
                            return (
                                <ListItem button key={link.id}>
                                    <ListItemText primary={
                                        getLabelText(props,link.label)
                                    }
                                        onClick={() => handleNavigation(link.href)} />
                                </ListItem>
                            );
                        })}
                    </List>
                    <div className={classes.grow} />
                    <div>
                        <SocialMediaList applyColor={false} />
                    </div>
                </Toolbar>
            </AppBar>
        </footer>
    );
}
function mapStateToProps(state) {
    return getPageText(state,pageTextKey);
}

const connectedFooter = connect(mapStateToProps)(Footer);
export  { connectedFooter as Footer };
