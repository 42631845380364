
import React from "react";
import ParallaxImage from "../../components/Parallax/Parallax";
import { IMAGES_URL, NAVIGATION_LINKS, QUESTIONS_STEPS } from "../../_const";
import { StartQuestions } from "../Questions/Start/StartQuestions";
import { useHistory } from "react-router-dom";

export default function Contact(props) {
    const history = useHistory();
    const {height = 500} = props;
    return (
        <section id={NAVIGATION_LINKS.CONTACT}>
            <ParallaxImage image={IMAGES_URL.CONTACT.BACKGROUND} height={height}>
                <StartQuestions handleStartClick={()=> history.push(`/${NAVIGATION_LINKS.QUESTIONS}/${QUESTIONS_STEPS.STEP_2}`)}/>
            </ParallaxImage>
        </section>

    );
}