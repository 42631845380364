import { Container, GridList, GridListTile, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { NAVIGATION_LINKS, PAGE_TEXT_KEYS } from "../../_const";
// import NavigationIcon from '@material-ui/icons/Navigation';
// import { Fade } from "react-reveal";
import { connect } from "react-redux";
import { getPageText, getLabelText } from "../../_helpers";

const pageTextKey = "TESTIMONIES";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    bubbleBox: {
        margin: '50px 10px',
        border: `4px solid ${theme.palette.primary.main}`,
        padding: 20,
        position: "relative",
        maxHeight: 250,
        boxShadow: theme.shadows[5]
    },
    bubbleUpArrow: {
        "&::before": {
            content: '""',
            width: 0,
            height: 0,
            position: "absolute",
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderTop: '10px solid transparent',
            borderBottom: `10px solid ${theme.palette.primary.main}`,
            right: "50%",
            top: -23,
        },
        "&::after": {
            content: '""',
            width: 0,
            height: 0,
            position: "absolute",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: '10px solid transparent',
            borderBottom: `10px solid #fff`,
            right: "50%",
            top: -18,
        }
    },
    bubbleLeftArrow: {
        "&::before": {
            content: '""',
            width: 0,
            height: 0,
            position: "absolute",
            borderLeft: "10px solid transparent",
            borderRight: `10px solid ${theme.palette.primary.main}`,
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            left: -23,
            top: 10
        },
        "&::after": {
            content: '""',
            width: 0,
            height: 0,
            position: "absolute",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid #fff",
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            left: -17,
            top: 10
        }
    },
    bubbleRightArrow: {
        "&::before": {
            content: '""',
            width: 0,
            height: 0,
            position: "absolute",
            borderLeft: `10px solid ${theme.palette.primary.main}`,
            borderRight: "10px solid transparent",
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            right: -23,
            top: 10
        },
        "&::after": {
            content: '""',
            width: 0,
            height: 0,
            position: "absolute",
            borderLeft: "10px solid #fff",
            borderRight: "10px solid transparent",
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            right: -17,
            top: 10
        }
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        marginTop: 10,
        marginRight: 10
    },
    image: {
        width: "100%",
        height: "100%",
        maxHeight: 200
    },
    arrow: {
        transform: 'rotate(90deg)',
        fontSize: '3em'
    },
    gridList: {
        paddingTop: theme.spacing(3)
    },
    gridListImage: {
        width: "100%"
    }
}));
function Testimonies(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    // const testimonies = [
    //     { id: 0, quote: "I felt like a superheroe training with Michael", avatarImage: "https://images-na.ssl-images-amazon.com/images/I/71om4dFYpdL._SL1500_.jpg", name: "Darian Mendez", beforeImage: "https://i.pinimg.com/originals/64/6e/ef/646eefa8e4992928bf8065519e1975c3.jpg", afterImage: "https://www.sonkifitness.com/wp-content/uploads/2012/02/SonkiHong1.jpg" },
    //     { id: 1, quote: "I felt like a superheroe training with Michael", avatarImage: "https://images-na.ssl-images-amazon.com/images/I/71om4dFYpdL._SL1500_.jpg", name: "Darian Mendez", beforeImage: "https://i.pinimg.com/originals/64/6e/ef/646eefa8e4992928bf8065519e1975c3.jpg", afterImage: "https://www.sonkifitness.com/wp-content/uploads/2012/02/SonkiHong1.jpg" }
    // ]
    const pictures = [
        {
            id: 0,
            sizes: {
                normal: {
                    cols: 3,
                    rows: 3
                },
                mobile: {
                    cols: 3,
                    rows: 3
                }
            },
            url: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Ftransformations%2Fimage_3%2Ccols_2%2Crows_2.jpg?alt=media&token=ba26fcd7-f368-4ef2-804e-bbabf3883253"
        },
        {
            id: 1,
            sizes: {
                normal: {
                    cols: 2,
                    rows: 2
                },
                mobile: {
                    cols: 2,
                    rows: 1
                }
            },
            url: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Ftransformations%2Fimage_1%2Ccols_1%2Crows_1.jpg?alt=media&token=f57bbffa-d6df-4593-8052-8dc7425d6b0a"
        },
        {
            id: 2,
            sizes: {
                normal: {
                    cols: 1,
                    rows: 2
                },
                mobile: {
                    cols: 1,
                    rows: 1
                }
            },
            url: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Ftransformations%2Fimage_4%2Ccols_2%2Crows_2.jpg?alt=media&token=32c617c7-680d-4739-9f7c-54b765fe7992"
        },
        {
            id: 3,
            sizes: {
                normal: {
                    cols: 1,
                    rows: 1
                },
                mobile: {
                    cols: 1,
                    rows: 1
                }
            },
            url: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Ftransformations%2Fimage_5%2Ccols_1%2Crows_1.jpg?alt=media&token=c0c53afa-4836-435d-a9c3-812a4aab7836"
        },
        {
            id: 4,
            sizes: {
                normal: {
                    cols: 1,
                    rows: 1
                },
                mobile: {
                    cols: 1,
                    rows: 1
                }
            },
            url: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Ftransformations%2Fimage_6%2Ccols_1%2Crows_1.jpg?alt=media&token=53d4153b-5be1-4fad-9dc1-bb65ff372241"
        },
        {
            id: 5,
            sizes: {
                normal: {
                    cols: 1,
                    rows: 1
                },
                mobile: {
                    cols: 1,
                    rows: 1
                }
            },
            url: "https://firebasestorage.googleapis.com/v0/b/everystephurt.appspot.com/o/assets%2Ftransformations%2Fimage_2%2Ccols_1%2Crows_1.jpg?alt=media&token=c93f887c-aa3b-48f7-9566-28e5249ccb45"
        }

    ];
 
    return (
        <section id={NAVIGATION_LINKS.TESTIMONIES} className={classes.root}>
            <Typography variant={isMobile ? "h4" : "h3"} align="center" color="primary">{getLabelText(props,PAGE_TEXT_KEYS.TESTIMONIES_TITLE_LABEL)}</Typography>
            <Container maxWidth="sm">
                <GridList cellHeight={160} className={classes.gridList} cols={3}>
                    {pictures.map((tile) => (
                        <GridListTile key={tile.id} 
                        cols={isMobile ? tile.sizes.mobile.cols || 1 : tile.sizes.normal.cols || 1} 
                        rows={isMobile ? tile.sizes.mobile.rows || 1 : tile.sizes.normal.rows || 1} 
                        >
                            <img src={tile.url} alt={tile.id + " transformation image"} />
                        </GridListTile>
                    ))}
                </GridList>
            </Container>
        </section>
    )
}

function mapStateToProps(state) {
    return getPageText(state,pageTextKey);
}

const connectedTestimonies = connect(mapStateToProps)(Testimonies);
export  { connectedTestimonies as Testimonies };

