import { makeStyles, useTheme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    parallax: {
        position: "relative",
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding:theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            backgroundAttachment: "unset"
        }
    },
    overlay: {
        position: "absolute",
        zIndex: 0,
        width: "100%",
        height: "100%",
        opacity: 0.5
    },
    title: {
        zIndex: 1
    }
}));

export default function ParallaxImage(props) {
    const { image, children, height=300, overlay = false, overlayColor = "secondary" } = props;
    const classes = useStyles();
    const theme = useTheme();
    return (


            <div className={classes.parallax} style={{ backgroundImage: `url(${image}`, minHeight: height }}>
                <div className={classes.title}>
                    {children}
                </div>
                {overlay && (<div className={classes.overlay} style={
                    {backgroundColor: theme.palette[overlayColor].light}
                }/>)}
            </div>

    )
}