export const ENGLISH_TEXT = {
  HEADER: {
    SERVICES: "Services",
    ABOUT_ME: "About Me",
    TRANSFORMATIONS: "Transformations",
    START_TRAINING: "Start Training",
  },
  SERVICES: {
    ONLINE_LABEL: "Online training",
    NUTRITION_LABEL: "Nutrition",
    PERSONAL_TRAINING_LABEL: "Personal Training",
    ONLINE_0: (
      <span>
        Friendly user <b>APP</b>
      </span>
    ),
    ONLINE_1: (
      <span>
        <b>24/7 </b> Support
      </span>
    ),
    ONLINE_2: <span>Nutrition tracker</span>,
    ONLINE_3: <span>Exercises library</span>,
    ONLINE_DESCRIPTION: (
      <span>
        I provide you with a <b>friendly user app </b> to guide you though your
        4 weeks. Show you how every exercise is done. 24/7 support via instant
        message and I also provide you with a nutrition plan to help you{" "}
        <b>achieve your fitness goal.</b>
      </span>
    ),
    NUTRITION_DESCRIPTION: (
      <span>
        I help you understand, and learn tricks and different ways to{" "}
        <b>control your nutrition</b>. I provide you with a pdf,{" "}
        <b>easy to read </b> and easy to learn with a (Sample Diet Plan)
      </span>
    ),
    NUTRITION_CARD_HEADER: "Sample Nutriton Plan",
    NUTRITION_0: (
      <span>
        A <b>pdf or nutrition plan </b> on how to eat healthy in your everyday
        life
      </span>
    ),
    NUTRITION_1: (
      <span>
        Helps you understand how nutrition <b>works</b>
      </span>
    ),
    NUTRITION_2: <span>A food list and a Sample diet plan</span>,
    NUTRITION_CONTACT_TITLE: "Need more information?",
    NUTRITION_CONTACT_BODY:
      "Provide your email, so we can send you all the information you need to start your journey.",
    NUTRITION_CONTACT_HELPER_TEXT: "Please provide a valid email",
    NUTRITION_CONTACT_BUTTON: "Send me Info",
    NUTRITION_CONTACT_SUCCESS_TITLE: "Thank you for providing your email",
    NUTRITION_CONTACT_SUCCESS_BODY: "We will contact you pretty soon!!",
    PERSON_0: (
      <span>
        {" "}
        <b>1 on 1</b> training
      </span>
    ),
    PERSON_1: (
      <span>
        Over <b>10</b> years of experience
      </span>
    ),
    PERSON_2: <span>Location: Your favorite gym</span>,
    PERSON_3: <span>Nutrition guidance</span>,
    PERSON_4: <span>Tracking Progress</span>,
    PERSON_5: <span>Technique and secrets</span>,
    PERSON_DESCRIPTION: (
      <span>
        Experience high quality training, <b>10 years of experience</b> applied
        to help you reach your fitness goal, and learn how to live a more active
        life style.
      </span>
    ),
    PERSON_CONTACT: "Do you live in the Miami Area?",
    PERSON_CONTACT_BUTTON_1: "Yes",
    PERSON_CONTACT_TITLE: (
      <span>
        <b>Would you like more information about the in-person training? </b>
      </span>
    ),
    PERSON_CONTACT_BODY: (
      <span>
        Provide us with your <b> number or email</b>, and we will contact you
        soon.
      </span>
    ),
    PERSON_CONTACT_OR: "OR",
    PERSON_CONTACT_PHONE_NUMBER: "Phone Number",
    PERSON_CONTACT_BUTTON_2: "Contact Me",
    PERSON_CONTACT_SUCCESS_TITLE: "Thank you for providing your info",
    PERSON_CONTACT_SUCCESS_BODY: "We will contact you pretty soon!!",
  },
  NEWSLETTER: {
    SIGN_UP: "Sign Up",
    SIGN_UP_DESCRIPTION: (
      <span>
        Sign Up for a <b>chance</b> to win a <b>FREE</b> Workout Plan
      </span>
    ),
    HELPER_TEXT: "Please provide a valid email",
    SUCCESS_MESSAGE_TITLE: <span>Thank you for providing your email</span>,
    SUCCESS_MESSAGE_BODY: (
      <span>
        Your <b>FREE </b> Workout Plan is on its way
      </span>
    ),
  },
  ABOUT_ME: {
    ABOUT_ME_TITLE_LABEL: "Every Step Hurt Documental",
  },
  TESTIMONIES: {
    TESTIMONIES_TITLE_LABEL: "Transformations",
  },
  START_QUESTIONS: {
    TITLE: " Are you ready to change your life?",
    BODY: "Complete the following questionnaire to start your journey today",
    BUTTON: "Start",
  },
  QUESTIONS: {
    NAME: "Name",
    AGE: "Age",
    SEX: "Sex",
    HEIGHT: "Height",
    WEIGHT: "Weight",
    ADDRESS: "Address",
    CITY: "City",
    STATE: "State",
    ZIP_CODE: "Zip Code",
    PHONE: "Phone",
    EMAIL: "Email",
    HEARTH_CONDITION:
      "Has your doctor ever said that you have a heart condition and that you should only perform physical activity recommended by a doctor?",
    YES_NO_ERROR_MESSAGE: "Please Select Yes or No",
    RESPONSE_MESSAGE: `If you have answered “Yes” to one or more of the above questions, consult your physician before
        engaging in physical activity. Tell your physician which questions you answered “Yes” to. After a
        medical evaluation, seek advice from your physician on what type of activity is suitable for your
        current condition.`,
    CHEST_PAIN:
      "Do you feel pain in your chest when you perform physical activity?",
    PAST_CHEST_PAIN: `In the past month, have you had chest pain when you were not performing any
        physical activity?`,
    LOSE_BALANCE: `Do you lose your balance because of dizziness or do you ever lose
        consciousness?`,
    BONE_PROBLEM: `Do you have a bone or joint problem that could be made worse by a change in
        your physical activity?`,
    PRESCRIBING_MEDICATION: `Is your doctor currently prescribing any medication for your blood pressure or
        for a heart condition?`,
    OTHER_REASON: `Do you know of any other reason why you should not engage in physical
        activity?`,
    CURRENT_POSITION: `What is your current occupation?`,
    CURRENT_POSITION_ERROR: `Please enter your current job position`,
    OCCUPATION_MOVEMENT: `Does your occupation require extended periods of sitting?`,
    REPETITIVE_MOVEMENT: `Does your occupation require extended periods of repetitive movements?`,
    HEEL_SHOES:
      "Does your occupation require you to wear shoes with a heel (dress shoes)?",
    HEARD_ABOUT_ME: "How did you heard about me?",
    HEARD_ABOUT_ME_OPTIONS: [
      { label: "Instagram", value: "instagram" },
      { label: "Facebook", value: "facebook" },
      { label: "Youtube", value: "youtube" },
      { label: "Friend/Family", value: "family" },
      { label: "Other", value: "other" },
    ],
    ANXIETY: "Does your occupation cause you anxiety (mental stress)?",
    RECREATIONAL_ACTIVITIES: `Do you partake in any recreational activities (golf, tennis, skiing, etc.)?`,
    HOBBIES: `Do you have any hobbies (reading, gardening, working on cars, exploring the Internet,etc.)?`,
    PLEASE_EXPLAIN: "Please explain",
    PREVIOUS_INJURIES: `Have you ever had any pain or injuries (ankle, knee, hip, back, shoulder, etc.)?`,
    SURGERIES: `Have you ever had any surgeries?`,
    CHRONIC_DISEASE: `Has a medical doctor ever diagnosed you with a chronic disease, such as
        coronary heart disease, coronary artery disease, hypertension (high blood
        pressure), high cholesterol or diabetes?`,
    MEDICATION: `Are you currently taking any medication?`,
    IDEAL_GOAL: `what is your ideal fitness goal? (to lose weight, become stronger, getting ready for a marathon?)`,
    GYM: `Where and what type of gym are you able to work out in?`,
    WORKOUT_DAYS: `How many days are you able to workout in a week?`,
    HEALTHY_FOOD_INTAKE: `What healthy foods do you eat on a regular?`,
    LAST_TIME_WORKOUT: `When was the last time you worked out?`,
    SEX_OPTIONS: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
    ],
    SEND_BUTTON: "Send",
    RESET_BUTTON: "Reset",
    QUESTION_GENERAL: "Contact Information",
    QUESTION_PHYSICAL_ACTIVITY:
      "PHYSICAL ACTIVITY READINESS QUESTIONNAIRE (PAR-Q)",
    QUESTION_MEDICAL: "GENERAL & MEDICAL QUESTIONNAIRE",
    QUESTION_QUESTIONS: "Questions",
    QUESTION_OCCUPATIONAL: "Occupational Questions",
    QUESTION_RECREATIONAL: "Recreational Questions",
    QUESTION_SUB_GROUP_MEDICAL: "Medical Questions",
    QUESTION_SMARTGOALS: "SMART Goals",
    YES_OPTION: "Yes",
    NO_OPTION: "No",
    QUESTION_SUBMIT_SUCCESS_TITTLE:
      "Thank you for completing this questionnaire",
    QUESTION_SUBMIT_SUCCESS_BODY: "We will be contacting you soon!",
    QUESTION_SUBMIT_ERROR_TITLE: "We are facing some technical difficulties ",
    QUESTION_SUBMIT_ERROR_BODY: "Please try again later!",
  },
};
