import React from 'react';
import { AppBar, Toolbar, IconButton, makeStyles, useTheme, Fade, useMediaQuery, Tabs, Tab, useScrollTrigger, Slide, Drawer, Zoom, Typography } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from "react-router-dom";
import { HEADER_NAVIGATION, IMAGES_URL } from '../../_const';
import SocialMediaList from '../SocialMediaList/SocialMediaList';
import CloseIcon from '@material-ui/icons/Close';
import { Fade as RevealFade } from 'react-reveal';
import { connect } from "react-redux";
import { getLabelText, getPageText } from '../../_helpers';
const pageTextKey = "HEADER";
const useStyles = makeStyles((theme) => ({
  primaryColor: {
    backgroundColor: theme.palette.primary.main
  },
  transparent: {
    backgroundColor: 'rgb(0 0 0 / 0%)'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    height: "100%",
    width: 300,
    margin: theme.spacing(2)
  },
  hide: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  profilePic: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: 100,
    height: 100,
    borderRadius: '100%',
    margin: theme.spacing(1),
  },
  center: {
    margin: "auto 0",
    whiteSpace: "nowrap"
  },
  flex: {
    display: "flex"
  },
  drawerRoot: {
    background: `url(${IMAGES_URL.HEADER.DRAWER_BACKGROUND})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    width: 200
  },
  drawer: {
    paddingTop: 10,
    paddingRight: 10,
    marginTop: theme.spacing(5),
    position: "absolute",
    zIndex: 1,
  },
  drawerCloseButton: {
    position: "absolute",
    right: 1,
    zIndex: 1
  },
  drawerOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: theme.palette.primary.main,
    opacity: 0.5,
    zIndex: 0
  },
  tabsColor: {
    color: theme.palette.secondary.main
  }
}));





function Header(props) {

  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const navigationLinks = React.useRef(HEADER_NAVIGATION);
  const getSectionIndex = (location) => {
    // location is an object like window.location
    let section = location.pathname.replace('/', '');
    let index = navigationLinks.current.findIndex(n => n.id === section);
    return index;
  }
  const [mobileMenuState, setMobileMenuState] = React.useState(false);
  const [navigationValue, setNavigationValue] = React.useState(getSectionIndex(history.location));
  const scrollTrigger = useScrollTrigger(
    {
      disableHysteresis: true,
      threshold: 50
    });


  const handleChange = (event, newValue) => {
    history.push(navigationLinks.current[newValue].href);
    setMobileMenuState(false);
  };




  history.listen((location) => {
    setNavigationValue(getSectionIndex(location));
  });


  const tabs = (isSideMenu) => (
    <Tabs
      value={navigationValue}
      aria-label="nav"
      orientation={isSideMenu ? "vertical" : "horizontal"}
      variant="fullWidth"
      onChange={handleChange}
      textColor="secondary"
      indicatorColor="secondary"
    >
      <Tab
        value={-1}
        label={"Empty"}
        style={{display:"none"}}
      />
      {navigationLinks.current.map((link, index) => {
        return (
          <Tab
            key={link.id}
            classes={
              { textColorSecondary: classes.tabsColor }
            }
            onClick={(event) => {
              event.preventDefault();
            }}
            label={(
              <RevealFade left delay={theme.transitions.duration.enteringScreen * index}>
                <Typography variant="button">{getLabelText(props,link.label)}</Typography>
              </RevealFade>)}
              value={index}
          />
        )
      })
      }
    </Tabs>
  );
  const sideMenu = (
    <Drawer classes={{ paper: classes.drawerRoot }} anchor="left" open={mobileMenuState} onClose={() => setMobileMenuState(false)} >
      <Zoom in={mobileMenuState} style={{ transitionDelay: mobileMenuState ? '500ms' : '0ms' }}>
        <IconButton className={classes.drawerCloseButton} color="secondary" onClick={() => setMobileMenuState(false)}><CloseIcon /></IconButton>
      </Zoom>
      <div className={classes.drawer}>
        {tabs(true)}
      </div>
      <div className={classes.drawerOverlay} />
    </Drawer>
  );
  const toolbar = (isTransparentNavBar) => (
    <Toolbar>
      <Fade in={isMobile} unmountOnExit mountOnEnter>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color={"inherit"}
          aria-label="open drawer"
          onClick={() => setMobileMenuState(true)}
        >
          <MenuIcon />
        </IconButton>
      </Fade>
      <Fade in={!isMobile} unmountOnExit>
        {tabs(false)}
      </Fade>
      <div className={classes.grow} />
      <div>
        <SocialMediaList applyColor={isTransparentNavBar} />
      </div>
    </Toolbar>
  )
  const transparentNavBar = (
    <Fade appear={false} in={!scrollTrigger}>
      <AppBar id="transparent-navbar" className={classes.transparent} elevation={0}>
        <Toolbar>
          <div className={classes.grow} />
          <Zoom in={true}>
            <img className={classes.logo} src={IMAGES_URL.LOGO} alt="Elite view logo" />
          </Zoom>
          <div className={classes.grow} />
        </Toolbar>
        {toolbar(true)}
      </AppBar>
    </Fade>
  )
  const regularNavBar = (
    <Slide appear={false} direction="down" in={scrollTrigger}>
      <AppBar className={classes.primaryColor} elevation={4}>
        {toolbar(false)}
      </AppBar>
    </Slide>
  )
  return (
    <React.Fragment>
      {transparentNavBar}
      {regularNavBar}
      {sideMenu}
    </React.Fragment>
  )
}
function mapStateToProps(state) {
  return getPageText(state,pageTextKey);
}

const connectedHeader = connect(mapStateToProps)(Header);
export  { connectedHeader as Header };
