import { Grid } from "@material-ui/core";
import React from "react";
import { Fade } from "react-reveal";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Icon from '@material-ui/core/Icon';
export default function SocialMediaList(props) {
    let socialMediaList = [
        { id: 0, name: "Instagram", action: "https://www.instagram.com/e.s.hfitness/", icon: <InstagramIcon />, color: "primary" },
        { id: 2, name: "Facebook", action: "https://www.facebook.com/profile.php?id=100001073917859", icon: <FacebookIcon />, color: "primary" },
        { id: 1, name: "Youtube", action: "https://www.youtube.com/channel/UCOTZ-Ust1h_RjHh7LuvgTHA", icon: <YouTubeIcon />, color: "primary" },
    ];
    const navigate = (action) => {
        window.open(action,"_blank");
    }
    return (
        <Grid container spacing={1}>
            {socialMediaList.map(social => {
                return (
                    <Grid item key={social.id}>
                        <Fade left>
                            <Icon fontSize="small" onClick={()=> navigate(social.action)}>
                                {social.icon}
                            </Icon>
                        </Fade>
                    </Grid>
                )
            })
            }
        </Grid>
    );
}